<template>
  <div class="abi-entry-signature">
    <div class="abi-entry-name" v-if="includeName">{{ abiEntryName }}</div>
    <div class="abi-entry-arguments" v-if="abiEntryArguments !== null">
      (<span :key="index" v-for="(abiEntryArgument, index) in abiEntryArguments">
        <template v-if="abiEntryArgument.length === 3">
          <span class="argument-type">{{ abiEntryArgument[0] }}</span> <span class="argument-modifier">{{ abiEntryArgument[1] }}</span> <span class="argument-name">{{ abiEntryArgument[2] }}</span>{{ index !== abiEntryArguments.length - 1 ? ', ' : '' }}
        </template>
        <template v-else>
          <span class="argument-type">{{ abiEntryArgument[0] }}</span> <span class="argument-name">{{ abiEntryArgument[1] }}</span>{{ index !== abiEntryArguments.length - 1 ? ', ' : '' }}
        </template>
      </span>)
    </div>
  </div>
</template>

<script>

  import useABI from '@/composables/useABI'

  export default {
    props: {
      abiEntrySignature: {
        type: String,
        required: true,
      },
      includeName: {
        type: Boolean,
        default: true,
      },
    },
    setup() {

      // composables
      const { getABIEntrySignaturePieces } = useABI()

      return Object.assign({
        getABIEntrySignaturePieces,
      })

    },
    computed: {
      abiEntryName() {
        const { abiEntryName } = this.getABIEntrySignaturePieces(this.abiEntrySignature)
        return abiEntryName
      },
      abiEntryArguments() {

        const { abiEntryArguments } = this.getABIEntrySignaturePieces(this.abiEntrySignature)

        if (abiEntryArguments === '') return null

        return abiEntryArguments
          .split(',')
          .map((abiEntryArgument) => {

            const abiEntryArgumentPieces = abiEntryArgument.trim().split(' ')

            // for now, we don't want to show the "indexed" modifier, but it's
            //  necessary for the backend to include so we'll just hide it from
            //  display
            if (abiEntryArgumentPieces.length === 3 && abiEntryArgumentPieces[1] === 'indexed') {
              abiEntryArgumentPieces.splice(1, 1)
            }

            return abiEntryArgumentPieces

          })

      },
    },
  }

</script>

<style lang="stylus" scoped>

  .abi-entry-signature
    @apply font-mono

    .abi-entry-name
      @apply font-bold
      @apply text-gray-800

    .abi-entry-arguments
      @apply text-sm
      @apply text-gray-600

    .argument-modifier
      @apply italic

    .argument-type
      @apply text-purple-500

</style>
