import { useStore } from 'vuex'

import Mixpanel from '@/plugins/mixpanel'

export default () => {

  // composables
  const store = useStore()

  const onNewAccountIntegrationError = (pendingAccountIntegration, type) => {

    switch (pendingAccountIntegration.provider) {

      case 'discord':
        return store.dispatch('toast/CREATE_TOAST', {
          text: `Could not add Discord ${type === 'discord-dm' ? 'direct message' : 'server'}! Please try again later.`,
          type: 'error',
        })

      case 'telegram':
        return store.dispatch('toast/CREATE_TOAST', {
          text: `Could not add Telegram ${type === 'telegram-group' ? 'group' : 'account'}! Please try again later.`,
          type: 'error',
        })

      default: return null // do nothing

    }

  }

  const onNewAccountIntegrationVerified = (newAccountIntegration, type) => {

    return store.dispatch('user/REFRESH_ACCOUNT_INTEGRATIONS', newAccountIntegration)
      .then(() => {

        switch (newAccountIntegration.provider) {

          case 'discord':

            return store.dispatch('toast/CREATE_TOAST', {
              text: `Discord ${type === 'discord-dm' ? 'direct message' : 'server'} successfully added!`,
              type: 'success',
            })

          case 'telegram': {

            const toastText = newAccountIntegration.defaultOutput.startsWith('-')
              ? `${store.state.app.dispatchBotName} successfully added to "${newAccountIntegration.name}" Telegram group!`
              : `Telegram account ${newAccountIntegration.name} successfully added!`

            return store.dispatch('toast/CREATE_TOAST', {
              text: toastText,
              type: 'success',
            })

          }

          default: return null // do nothing

        }

      })
      .catch((error) => {
        onNewAccountIntegrationError(newAccountIntegration, type)
        throw error
      })

  }

  const openCreateAccountIntegrationModal = (type) => {

    return new Promise((resolve, reject) => {

      switch (type) {

        case 'discord-dm':
        case 'discord-server':
        case 'telegram-group':
        case 'telegram-private': {

          const provider = type.replace(/([^-]*).*/, '$1')

          return store.state.api.dispatch
            .post('/integrations', { provider })
            .then((response) => {

              const pendingAccountIntegration = response.data

              const popupUrl = (() => {

                switch (provider) {

                  case 'discord': {
                    if (type === 'discord-dm') {
                      return `${store.state.app.discordBotDirectMessageUrl}&scope=identify&state=${pendingAccountIntegration.secret}`
                    }
                    return `${store.state.app.discordBotGuildUrl}&scope=bot%20identify&state=${pendingAccountIntegration.secret}`
                  }

                  case 'telegram': {
                    const startKey = type === 'telegram-group' ? 'startgroup' : 'start'
                    return `${store.state.app.telegramBotUrl}?${startKey}=${pendingAccountIntegration.secret}`
                  }

                  default: return null

                }

              })()

              store.commit('modals/SET_ALLOW_MODAL_CLOSE', false)

              store.dispatch('modals/OPEN_MODAL', {
                name: 'CreateAccountIntegrationModal',
                props: {
                  provider,
                  popupUrl,
                  pendingAccountIntegration,
                  onError: () => {
                    return onNewAccountIntegrationError(pendingAccountIntegration, type)
                      .finally(() => {
                        reject(pendingAccountIntegration)
                      })
                  },
                  onSuccess: (accountIntegration) => {

                    Mixpanel.onReady((mixpanel) => {
                      mixpanel.track('New Account Integration Added', { accountIntegration })
                    })

                    return onNewAccountIntegrationVerified(accountIntegration, type)
                      .finally(() => {
                        resolve(accountIntegration)
                      })
                  },
                },
              })

            })
            .catch((error) => {
              reject(error)
            })

        }

        case 'webhook':

          return store.dispatch('modals/OPEN_MODAL', {
            name: 'CreateAccountWebhookModal',
            props: {
              onSuccess: (accountIntegration) => {

                Mixpanel.onReady((mixpanel) => {
                  mixpanel.track('New Account Integration Added', { accountIntegration })
                })

                return store.dispatch('user/REFRESH_ACCOUNT_INTEGRATIONS', accountIntegration)
                  .then(() => {

                    resolve(accountIntegration)

                    return store.dispatch('toast/CREATE_TOAST', {
                      text: 'Webhook successfully added!',
                      type: 'success',
                    })

                  })
                  .catch((error) => {
                    reject(error)
                    return store.dispatch('toast/CREATE_TOAST', {
                      text: 'Could not add webhook! Please try again later.',
                      type: 'error',
                    })
                  })
              },
              onError: reject,
            },
          })

        default:
          return null // do nothing
      }

    })

  }

  return {
    openCreateAccountIntegrationModal,
  }

}
