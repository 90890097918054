import { onBeforeUnmount, ref } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'

export default (props) => {

  const shouldPreventRouteChange = ref(true)

  const message = props.message || 'Are you sure you want to leave this page?'

  const onBeforeUnload = (event) => {
    if (!shouldPreventRouteChange.value) return
    event.preventDefault()
    event.returnValue = message
  }

  window.addEventListener('beforeunload', onBeforeUnload)

  onBeforeRouteLeave((to) => {
    // @NOTE: vue router params are always strings
    if (!shouldPreventRouteChange.value || to.params.force === 'true') return true
    // eslint-disable-next-line no-alert
    return window.confirm(message)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', onBeforeUnload)
  })

  const removeBeforeUnloadListener = () => {
    shouldPreventRouteChange.value = false
    window.removeEventListener('beforeunload', onBeforeUnload)
  }

  return {
    shouldPreventRouteChange,
    removeBeforeUnloadListener,
  }

}
