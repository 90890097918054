<template>
  <div
    :class="[position]"
    ref="tooltipContainer"
    :ariaLabel="tooltipText"
    class="tooltip-container"
    @mouseover="positionTooltip"
  >
    <slot name="trigger" />
    <div ref="opacityContainer" class="opacity-container">
      <div ref="tooltipArrow" class="tooltip-arrow"></div>
      <div ref="tooltip" class="tooltip" :style="{ width, maxWidth }">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    props: {
      width: {
        type: String,
        default: 'max-content',
      },
      maxWidth: {
        type: String,
        default: null,
      },
    },
    computed: {
      $tooltip() {
        return this.$refs.tooltip
      },
      $tooltipArrow() {
        return this.$refs.tooltipArrow
      },
      $tooltipContainer() {
        return this.$refs.tooltipContainer
      },
      $opacityContainer() {
        return this.$refs.opacityContainer
      },
    },
    data() {
      return {
        edgeMargin: 8,
        arrowWidth: 0,
        arrowOffset: 2,
        position: 'top',
        tooltipText: null,
      }
    },
    mounted() {

      window.addEventListener('scroll', this.positionTooltipIfVisible)
      window.addEventListener('resize', this.positionTooltipIfVisible)

      this.tooltipText = this.$tooltip.innerText

      // figure out the width tooltip arrow by cloning it and placing it
      //  off-screen - note that we can't just measure the actual tooltip arrow
      //  since it could be hidden in the dom and thus have no width value in
      //  the getBoundingClientRect() result
      const $clone = this.$tooltipArrow.cloneNode(true)

      $clone.style.top = '-1000000px'
      $clone.style.left = '-1000000px'
      $clone.style.transform = 'unset'
      $clone.style.display = 'absolute'

      document.body.appendChild($clone)
      this.arrowWidth = $clone.getBoundingClientRect().width
      document.body.removeChild($clone)

      this.positionTooltip()

    },
    beforeUnmount() {
      window.addEventListener('scroll', this.positionTooltipIfVisible)
      window.addEventListener('resize', this.positionTooltipIfVisible)
    },
    methods: {
      positionTooltipIfVisible() {
        if (!this.$opacityContainer) return
        if (window.getComputedStyle(this.$opacityContainer).opacity === '0') return
        this.positionTooltip()
      },
      positionTooltip() {

        const { width: tooltipWidth, height: tooltipHeight } = this.$tooltip.getBoundingClientRect()
        const { width: containerWidth, left: containerLeft, top: containerTop, bottom: containerBottom } = this.$tooltipContainer.getBoundingClientRect()

        const halfArrowWidth = this.arrowWidth / 2
        const halfTooltipWidth = tooltipWidth / 2
        const halfContainerWidth = containerWidth / 2

        this.$tooltipArrow.style.left = `${containerLeft + halfContainerWidth - halfArrowWidth}px`

        // position left / right
        if (containerLeft + halfContainerWidth - halfTooltipWidth < this.edgeMargin) {
          this.$tooltip.style.left = `${this.edgeMargin}px`

        } else if (containerLeft + halfContainerWidth + halfTooltipWidth + this.edgeMargin < window.innerWidth) {
          this.$tooltip.style.left = `${containerLeft + halfContainerWidth - halfTooltipWidth}px`

        } else {
          this.$tooltip.style.left = `${window.innerWidth - tooltipWidth - this.edgeMargin}px`

        }

        // position top / bottom
        if (containerTop - tooltipHeight - halfArrowWidth - this.arrowOffset - this.edgeMargin > 0) {
          this.$tooltip.style.top = `${containerTop - tooltipHeight - halfArrowWidth - this.arrowOffset}px`
          this.$tooltipArrow.style.top = `${containerTop - this.arrowOffset - this.arrowWidth - 2}px`
          this.position = 'top'

        } else {
          this.$tooltip.style.top = `${containerBottom + halfArrowWidth + this.arrowOffset}px`
          this.$tooltipArrow.style.top = `${containerBottom + this.arrowOffset + 2}px`
          this.position = 'bottom'
        }

      },
    },
  }

</script>

<style lang="stylus">

  // @NOTE: styles imported globally in main.js

</style>
