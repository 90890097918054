<template>
  <div class="input-container">

    <div class="label-container">
      <FormLabel
        v-if="showLabel"
        :formName="formName"
        :fieldName="fieldName"
      />
      <slot name="label-extra-content" />
    </div>

    <FormLabelDescription
      :formName="formName"
      :fieldName="fieldName"
      v-if="field.labelDescription"
    />

    <div v-if="field.metaType === 'multi'">
      <div
        :key="option.id"
        class="option-container"
        v-for="(option, index) in field.options"
      >
        <input
          ref="formElement"
          :type="field.type"
          v-model="field.value"
          :value="option.value"
          :id="`${uuid}-${index}`"
          @change="$emit('change', field)"
          :disabled="field.disabled || option.disabled"
          :class="{ 'error': field.isDirty && !!field.error, 'toggle': isToggle, [toggleSize]: toggleSize }"
        />
        <div
          class="option-content"
          v-if="option.label || option.description"
          :class="{ 'disabled': field.disabled || option.disabled }"
        >
          <label
            class="label-text"
            :for="`${uuid}-${index}`"
          >
            {{ option.label }}
          </label>
          <div
            class="description-text"
            v-if="option.description"
          >
            {{ option.description }}
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="option-container">
        <input
          :id="uuid"
          ref="formElement"
          :type="field.type"
          v-model="field.value"
          :disabled="field.disabled"
          :required="field.required"
          @change="$emit('change', field)"
          :class="{ 'error': field.isDirty && !!field.error, 'toggle': isToggle, [toggleSize]: toggleSize }"
        />
        <div
          class="option-content"
          :class="{ 'disabled': field.disabled }"
          v-if="field.placeholder || field.description"
        >
          <label
            :for="uuid"
            class="label-text"
          >
            {{ field.placeholder }}
          </label>
          <div
            class="description-text"
            v-if="field.description"
          >
            {{ field.description }}
          </div>
        </div>
      </div>
    </div>

    <FormNote
      v-if="field.note"
      :formName="formName"
      :fieldName="fieldName"
    />

    <FormError
      v-if="showError"
      :formName="formName"
      :fieldName="fieldName"
    />

  </div>
</template>

<script>

  import { toRefs } from 'vue'

  import useFormField from '@/composables/useFormField'

  import FormNote from '@/components/forms/FormNote.vue'
  import FormError from '@/components/forms/FormError.vue'
  import FormLabel from '@/components/forms/FormLabel.vue'
  import FormLabelDescription from '@/components/forms/FormLabelDescription.vue'

  export default {
    emits: ['change'],
    components: {
      FormNote,
      FormError,
      FormLabel,
      FormLabelDescription,
    },
    props: {
      formName: {
        type: String,
        required: true,
      },
      fieldName: {
        type: String,
        required: true,
      },
      isToggle: {
        type: Boolean,
        default: false,
      },
      toggleSize: {
        type: String,
        default: null,
        validator(value) {
          return value === 'normal' || value === 'large'
        },
      },
      showLabel: {
        type: Boolean,
        default: true,
      },
      showError: {
        type: Boolean,
        default: true,
      },
    },
    setup(props) {
      return useFormField(toRefs(props))
    },
  }

</script>

<style lang="stylus" scoped>

  input:not(.toggle)
    @apply mt-1

  .option-container
    @apply items-start

  .option-content
    @apply ml-2

    &.disabled
    &.disabled .label-text
      @apply opacity-50
      @apply cursor-not-allowed

  .label-text
    @apply text-base
    @apply font-normal
    @apply text-gray-800
    @apply cursor-pointer

  .description-text
    @apply text-sm
    @apply text-gray-600

</style>
