<template>

  <Header />

  <main>
    <div class="container">

      <router-link class="return-to-dashboard-link" :to="previousRoutePath">
        <ArrowIcon /> Back to {{ previousRouteName }}
      </router-link>

      <form :class="{ 'disabled': isAPIReadOnly }" @submit="submit">

        <h2>Create a Patch</h2>

        <div class="step" ref="step-1">
          <div class="step-header">
            <span class="step-number">1</span>
            <h5>Trigger type</h5>
            <button
              type="button"
              @click="currentStep = 1"
              class="link edit-step-link"
              v-if="currentStep !== 1 && stepSubmissions[0]"
            >
              Edit
            </button>
            <span class="flex-grow" />
            <CheckIcon class="check-icon" v-if="currentStep !== 1 && stepSubmissions[0]" />
          </div>
          <template v-if="currentStep === 1">
            <div class="step-body">
              <FormSelect :formName="formName" fieldName="triggerId" :isSearchable="true" />
            </div>
            <div class="step-buttons">
              <button
                type="button"
                class="secondary small"
                :disabled="isAPIReadOnly"
                @click="$router.push(previousRoutePath)"
              >
                Cancel
              </button>
              <button
                type="button"
                class="small"
                @click="submitCurrentStep()"
                :disabled="isAPIReadOnly || !isStep1Valid"
              >
                Set Trigger conditions
              </button>
            </div>
          </template>
          <template v-else>
            <div class="step-summary" v-if="stepSubmissions[0]">
              <div class="details-list">
                <div>
                  <img class="inline-icon" :src="selectedTriggerOption.iconUrl" />
                  <span>{{ selectedTriggerOptionLabel }}</span>
                </div>
              </div>
            </div>
          </template>
        </div>

        <div class="step trigger-conditions" ref="step-2">
          <div class="step-header">
            <span class="step-number">2</span>
            <h5>Trigger conditions</h5>
            <button
              type="button"
              @click="currentStep = 2"
              class="link edit-step-link"
              v-if="currentStep !== 2 && stepSubmissions[1]"
            >
              Edit
            </button>
            <span class="flex-grow" />
            <CheckIcon class="check-icon" v-if="currentStep !== 2 && stepSubmissions[1]" />
          </div>
          <template v-if="currentStep === 2">
            <div class="step-body">

              <template v-if="!isV2DexTrigger">
                <FormSelect :formName="formName" fieldName="networkId" :isSearchable="true" />
              </template>

              <template v-if="isBalanceChangeTrigger || isNFTTrigger">
                <FormSelect :formName="formName" fieldName="contractId" :isSearchable="true" type="rich">
                  <template #extra-content>
                    <div class="token-request-note">
                      Don't see the {{ isNFTTrigger ? 'collection' : 'token' }} you want?
                      <button type="button" class="link" @click="openTokenRequestModal">Submit a request!</button>
                    </div>
                  </template>
                </FormSelect>
              </template>

              <template v-if="isBalanceChangeTrigger">

                <div :class="{ 'mb-6': selectedTriggerOption.apiRecord.slug !== 'balance-threshold-reached' }">
                  <FormInput :formName="formName" fieldName="addressBalanceChangeAddressList" ref="addressBalanceChangeAddressList" />
                </div>

                <template v-if="selectedTriggerOption.apiRecord.slug === 'balance-threshold-reached'">
                  <FormInput :formName="formName" fieldName="addressBalanceChangeThreshold" />
                  <FormCheckbox :formName="formName" fieldName="addressBalanceChangeThresholdDirection" />
                </template>

              </template>

              <template v-if="isUserContractTrigger">
                <FormSelect :formName="formName" fieldName="userContractId" :isSearchable="true" type="rich">
                  <template #extra-content>
                    <button type="button" class="link sticky-button" @click="openCreateUserContractModal()">
                      <SmartContractIcon />
                      Add a smart contract
                    </button>
                  </template>
                </FormSelect>
                <FormSelect :formName="formName" fieldName="userContractType" v-if="!!formFields.userContractType.value" />
                <!--
                  @NOTE: v-show is used here instead of v-if, because otherwise
                  the value watcher in the useFormField composable does not fire
                  when the value is initially set, which causes a mildly
                  annoying bug where the "valid" checkmark does not show in this
                  input until the value is changed after being initially show
                  the first time a contract is chosen
                -->
                <FormInput :formName="formName" fieldName="userContractAddress" v-show="!!formFields.userContractAddress.value" />
                <RichChecklist
                  :formName="formName"
                  type="contractABIEntries"
                  fieldName="userContractProxyEvents"
                  v-if="formFields.userContractProxyEvents.options.length !== 0"
                >
                  <template #label-extra-content>
                    <span class="badge purple subtle mx-2">PROXY</span>
                    <Tooltip class="self-center">
                      <template #trigger>
                        <InfoIcon />
                      </template>
                      <p class="font-bold text-lg">
                        This appears to be a proxy contract
                      </p>
                      <p class="my-4">
                        You can monitor events from this proxy contract or the
                        current implementation contract below.
                      </p>
                      <a target="_blank" href="https://ethereum.org/en/developers/docs/smart-contracts/upgrading/#proxy-patterns">Learn more</a>
                    </Tooltip>
                  </template>
                </RichChecklist>
                <RichChecklist
                  :formName="formName"
                  type="contractABIEntries"
                  :isLoading="areUserContractABIEntriesLoading"
                  fieldName="userContractImplementationEvents"
                  v-if="formFields.userContractImplementationEvents.options.length !== 0 || areUserContractABIEntriesLoading"
                >
                  <template v-if="shouldShowImplementationLabels" #label-extra-content>
                    <span class="badge purple subtle mx-2">IMPLEMENTATION</span>
                    <Tooltip class="self-center" maxWidth="25rem">
                      <template #trigger>
                        <InfoIcon />
                      </template>
                      <span class="font-mono">{{ selectedUserContractOption.apiRecord.implementationAddress }}</span>
                    </Tooltip>
                  </template>
                </RichChecklist>
                <RichChecklist
                  :formName="formName"
                  type="contractABIEntries"
                  fieldName="userContractProxyFunctions"
                  v-if="formFields.userContractProxyFunctions.options.length !== 0"
                >
                  <template #label-extra-content>
                    <span class="badge purple subtle mx-2">PROXY</span>
                    <Tooltip class="self-center">
                      <template #trigger>
                        <InfoIcon />
                      </template>
                      <p class="font-bold text-lg">
                        This appears to be a proxy contract
                      </p>
                      <p class="my-4">
                        You can monitor functions from this proxy contract or
                        the current implementation contract below.
                      </p>
                      <a target="_blank" href="https://ethereum.org/en/developers/docs/smart-contracts/upgrading/#proxy-patterns">Learn more</a>
                    </Tooltip>
                  </template>
                </RichChecklist>
                <RichChecklist
                  :formName="formName"
                  type="contractABIEntries"
                  :isLoading="areUserContractABIEntriesLoading"
                  fieldName="userContractImplementationFunctions"
                  v-if="formFields.userContractImplementationFunctions.options.length !== 0 || areUserContractABIEntriesLoading"
                >
                  <template v-if="shouldShowImplementationLabels" #label-extra-content>
                    <span class="badge purple subtle mx-2">IMPLEMENTATION</span>
                    <Tooltip class="self-center" maxWidth="25rem">
                      <template #trigger>
                        <InfoIcon />
                      </template>
                      <span class="font-mono">{{ selectedUserContractOption.apiRecord.implementationAddress }}</span>
                    </Tooltip>
                  </template>
                </RichChecklist>
              </template>

            </div>
            <div class="step-buttons">
              <button
                type="button"
                class="secondary small"
                @click="currentStep -= 1"
              >
                Back
              </button>
              <button
                type="button"
                class="small"
                :disabled="!isStep2Valid"
                @click="submitCurrentStep()"
              >
                Continue
              </button>
            </div>
          </template>
          <template v-else>
            <div class="step-summary" v-if="stepSubmissions[1]">

              <div class="details-list">

                <div>
                  <strong>{{ formFields.networkId.label }}:</strong>
                  <img class="inline-icon" :src="selectedNetworkOption.iconUrl" />
                  <span>{{ selectedNetworkOption.label }}</span>
                </div>

                <template v-if="isBalanceChangeTrigger || isNFTTrigger">
                  <div>
                    <strong>{{ formFields.contractId.label }}:</strong>
                    <img class="inline-icon" :src="selectedContractOption.iconUrl" />
                    <span>{{ selectedContractOption.label }}</span>
                  </div>
                </template>

                <template v-if="isBalanceChangeTrigger">

                  <!-- when only one address was entered, don't show it as a list -->
                  <template v-if="formFields.addressBalanceChangeAddressList.value.length === 1">
                    <div>
                      <strong>Address:</strong>
                      <span class="break-all">{{ formFields.addressBalanceChangeAddressList.value[0] }}</span>
                    </div>
                  </template>

                  <template v-else>
                    <div>
                      <strong>{{ formFields.addressBalanceChangeAddressList.label }}:</strong>
                      <div class="break-all">
                        <ol>
                          <li
                            :key="address"
                            v-for="address in formFields.addressBalanceChangeAddressList.value"
                          >
                            {{ address }}
                          </li>
                        </ol>
                      </div>
                    </div>
                  </template>

                  <!-- balance threshold inputs -->
                  <template v-if="selectedTriggerOption.apiRecord.slug === 'balance-threshold-reached'">
                    <div>
                      <strong>{{ formFields.addressBalanceChangeThreshold.label }}:</strong>
                      <span>
                        Goes
                        {{ addressBalanceChangeThresholdDirectionCombined }}
                        {{ formFields.addressBalanceChangeThreshold.value }}
                      </span>
                    </div>
                  </template>

                </template>

                <template v-if="isUserContractTrigger">
                  <div>
                    <strong>{{ formFields.userContractId.label }}:</strong>
                    <img class="inline-icon" :src="selectedUserContractOption.iconUrl" />
                    <span>{{ selectedUserContractOption.label }}</span>
                  </div>
                  <div>
                    <strong>{{ formFields.userContractType.label }}:</strong>
                    <img class="inline-icon" :src="selectedNetworkOption.iconUrl" />
                    <span>{{ selectedUserContractOption.apiRecord.type === 'other' ? titleCase(selectedUserContractOption.apiRecord.type) : selectedUserContractOption.apiRecord.type.toUpperCase() }}</span>
                  </div>
                  <div v-if="selectedUserContractOption">
                    <strong>{{ formFields.userContractAddress.label }}:</strong>
                    <img class="inline-icon" :src="selectedNetworkOption.iconUrl" />
                    <span>{{ selectedUserContractOption.apiRecord.address }}</span>
                  </div>
                  <div class="stacked-item" v-if="formFields.userContractProxyEvents.value.length !== 0">
                    <strong class="flex items-baseline">
                      <span>{{ formFields.userContractProxyEvents.label }}</span>
                      <span class="badge purple subtle ml-2">PROXY</span>
                    </strong>
                    <div class="break-all">
                      <ol>
                        <li
                          class="mb-1"
                          :key="eventSignature"
                          v-for="eventSignature in formFields.userContractProxyEvents.value"
                        >
                          <ContractABIEntrySignature :abiEntrySignature="eventSignature" />
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="stacked-item" v-if="formFields.userContractImplementationEvents.value.length !== 0">
                    <strong class="flex items-baseline">
                      <span>{{ formFields.userContractImplementationEvents.label }}</span>
                      <!--

                        only show a colon after the event label if we're NOT
                        showing the IMPLEMENTATION badge (because it looks weird
                        to have the badge AND colon there, but it looks weird to
                        NOT have the colon if the badge isn't there since
                        everything above has a colon...)

                        @NOTE: we specificly use options.length instead of
                        value.length in the conditional below since we always
                        want to display the IMPLEMENTATION badge if the contract
                        uses a proxy, and NOT only if the user simply *selected*
                        a proxy event

                      -->
                      <template v-if="formFields.userContractProxyEvents.options.length === 0">:</template>
                      <template v-else>
                        <span class="badge purple subtle ml-2">IMPLEMENTATION</span>
                      </template>
                    </strong>
                    <div class="break-all">
                      <ol>
                        <li
                          class="mb-1"
                          :key="eventSignature"
                          v-for="eventSignature in formFields.userContractImplementationEvents.value"
                        >
                          <ContractABIEntrySignature :abiEntrySignature="eventSignature" />
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="stacked-item" v-if="formFields.userContractProxyFunctions.value.length !== 0">
                    <strong class="flex items-baseline">
                      <span>{{ formFields.userContractProxyFunctions.label }}</span>
                      <span class="badge purple subtle ml-2">PROXY</span>
                    </strong>
                    <div class="break-all">
                      <ol>
                        <li
                          class="mb-1"
                          :key="functionSignature"
                          v-for="functionSignature in formFields.userContractProxyFunctions.value"
                        >
                          <ContractABIEntrySignature :abiEntrySignature="functionSignature" />
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="stacked-item" v-if="formFields.userContractImplementationFunctions.value.length !== 0">
                    <strong class="flex items-baseline">
                      <span>{{ formFields.userContractImplementationFunctions.label }}</span>
                      <!--

                        only show a colon after the function label if we're NOT
                        showing the IMPLEMENTATION badge (because it looks weird
                        to have the badge AND colon there, but it looks weird to
                        NOT have the colon if the badge isn't there since
                        everything above has a colon...)

                        @NOTE: we specificly use options.length instead of
                        value.length in the conditional below since we always
                        want to display the IMPLEMENTATION badge if the contract
                        uses a proxy, and NOT only if the user simply *selected*
                        a proxy function

                      -->
                      <template v-if="formFields.userContractProxyFunctions.options.length === 0">:</template>
                      <template v-else>
                        <span class="badge purple subtle ml-2">IMPLEMENTATION</span>
                      </template>
                    </strong>
                    <div class="break-all">
                      <ol>
                        <li
                          class="mb-1"
                          :key="functionSignature"
                          v-for="functionSignature in formFields.userContractImplementationFunctions.value"
                        >
                          <ContractABIEntrySignature :abiEntrySignature="functionSignature" />
                        </li>
                      </ol>
                    </div>
                  </div>
                </template>

              </div>

            </div>
          </template>
        </div>

        <div class="step" ref="step-3">
          <div class="step-header">
            <span class="step-number">3</span>
            <h5>Action type</h5>
            <button
              type="button"
              @click="currentStep = 3"
              class="link edit-step-link"
              v-if="currentStep !== 3 && stepSubmissions[2]"
            >
              Edit
            </button>
            <span class="flex-grow" />
            <CheckIcon class="check-icon" v-if="currentStep !== 3 && stepSubmissions[2]" />
          </div>
          <template v-if="currentStep === 3">
            <div class="step-body">
              <FormSelect :formName="formName" fieldName="actionId" :isSearchable="true" />
            </div>
            <div class="step-buttons">
              <button
                type="button"
                class="secondary small"
                @click="currentStep -= 1"
              >
                Back
              </button>
              <button
                type="button"
                class="small"
                :disabled="!isStep3Valid"
                @click="submitCurrentStep()"
              >
                Continue
              </button>
            </div>
          </template>
          <template v-else>
            <div class="step-summary" v-if="stepSubmissions[2]">
              <div class="details-list">
                <div>
                  <img class="inline-icon" :src="selectedActionOption.iconUrl" />
                  <span>{{ selectedActionOptionLabel }}</span>
                </div>
              </div>
            </div>
          </template>
        </div>

        <div class="step" ref="step-4">
          <div class="step-header">
            <span class="step-number">4</span>
            <h5>Action details</h5>
            <button
              type="button"
              @click="currentStep = 4"
              class="link edit-step-link"
              v-if="currentStep !== 4 && stepSubmissions[3]"
            >
              Edit
            </button>
            <span class="flex-grow" />
            <CheckIcon class="check-icon" v-if="currentStep !== 4 && stepSubmissions[3]" />
          </div>
          <template v-if="currentStep === 4">
            <div class="step-body">

              <template v-if="selectedActionOption.apiRecord.slug === 'email'">
                <FormInput :formName="formName" fieldName="emailActionEvent" />
                <FormInput :formName="formName" fieldName="email" />
              </template>

              <template v-if="selectedActionOption.apiRecord.slug === 'telegram'">
                <FormSelect :formName="formName" fieldName="telegramActionEvent" :isSearchable="true" />
                <FormSelect :formName="formName" fieldName="telegramAccountIntegrationId" :isSearchable="true" />
              </template>

              <template v-if="selectedActionOption.apiRecord.slug === 'discord'">
                <FormSelect :formName="formName" fieldName="discordActionEvent" :isSearchable="true" />
                <FormSelect :formName="formName" fieldName="discordAccountIntegrationId" :isSearchable="true" />
                <FormSelect :formName="formName" fieldName="discordChannelId" :isSearchable="true" v-if="selectedDiscordActionEventOption && selectedDiscordActionEventOption.value === 'server-channel'" />
              </template>

              <template v-if="selectedActionOption.apiRecord.slug === 'dispatch-monitor'">
                <FormInput :formName="formName" fieldName="dispatchMonitorActionEvent" />
              </template>

              <template v-if="selectedActionOption.apiRecord.slug === 'webhook'">
                <FormInput :formName="formName" fieldName="webhookActionEvent" />
                <FormSelect :formName="formName" fieldName="webhookAccountIntegrationId" :isSearchable="true" />
                <FormInput :formName="formName" fieldName="webhookUrl" />
              </template>

            </div>
            <div class="step-buttons">
              <button
                type="button"
                class="secondary small"
                @click="currentStep -= 1"
              >
                Back
              </button>
              <!--
                @NOTE: for some strange reason, step 4 does not ever submit the
                form when you press enter in a text field (e.g. email), so we'll
                just remove type="button" from the continue button here, which
                will make the form submit (and the logic in the submit() method
                will intercept that and proceed to the next step)...
              -->
              <button
                class="small"
                :disabled="!isStep4Valid"
                @click="submitCurrentStep()"
              >
                Continue
              </button>
            </div>
          </template>
          <template v-else>
            <div class="step-summary" v-if="stepSubmissions[3]">

              <div class="details-list">

                <template v-if="selectedActionOption.apiRecord.slug === 'email'">
                  <div>
                    <strong>{{ formFields.emailActionEvent.label }}:</strong>
                    <img class="inline-icon" :src="selectedActionOption.iconUrl" />
                    <span>{{ formFields.emailActionEvent.value }}</span>
                  </div>
                  <div>
                    <strong>{{ formFields.email.label }}:</strong>
                    <img class="inline-icon" :src="selectedActionOption.iconUrl" />
                    <span>{{ formFields.email.value }}</span>
                  </div>
                </template>

                <template v-if="selectedActionOption.apiRecord.slug === 'telegram'">
                  <div>
                    <strong>{{ formFields.telegramActionEvent.label }}:</strong>
                    <img class="inline-icon" :src="selectedActionOption.iconUrl" />
                    <span>{{ selectedTelegramActionEventLabel }}</span>
                  </div>
                  <div>
                    <strong>{{ formFields.telegramAccountIntegrationId.label }}:</strong>
                    <img class="inline-icon" :src="selectedActionOption.iconUrl" />
                    <span class="break-all">{{ selectedTelegramIntegrationLabel }}</span>
                  </div>
                </template>

                <template v-if="selectedActionOption.apiRecord.slug === 'discord'">
                  <div>
                    <strong>{{ formFields.discordActionEvent.label }}:</strong>
                    <img class="inline-icon" :src="selectedActionOption.iconUrl" />
                    <span>{{ selectedDiscordActionEventLabel }}</span>
                  </div>
                  <div>
                    <strong>{{ formFields.discordAccountIntegrationId.label }}:</strong>
                    <img class="inline-icon" :src="selectedActionOption.iconUrl" />
                    <span class="break-all">{{ selectedDiscordIntegrationLabel }}</span>
                  </div>
                  <div v-if="selectedDiscordActionEventOption && selectedDiscordActionEventOption.value === 'server-channel'">
                    <strong>{{ formFields.discordChannelId.label }}:</strong>
                    <img class="inline-icon" :src="selectedActionOption.iconUrl" />
                    <span class="break-all">{{ selectedDiscordServerChannelLabel }}</span>
                  </div>
                </template>

                <template v-if="selectedActionOption.apiRecord.slug === 'dispatch-monitor'">
                  <div>
                    <strong>{{ formFields.dispatchMonitorActionEvent.label }}:</strong>
                    <img class="inline-icon" :src="selectedActionOption.iconUrl" />
                    <span>{{ formFields.dispatchMonitorActionEvent.value }}</span>
                  </div>
                </template>

                <template v-if="selectedActionOption.apiRecord.slug === 'webhook'">
                  <div>
                    <strong>{{ formFields.webhookActionEvent.label }}:</strong>
                    <img class="inline-icon" :src="selectedActionOption.iconUrl" />
                    <span>{{ formFields.webhookActionEvent.value }}</span>
                  </div>
                  <div>
                    <strong>{{ formFields.webhookAccountIntegrationId.label }}:</strong>
                    <img class="inline-icon" :src="selectedActionOption.iconUrl" />
                    <span class="break-all">{{ selectedWebhookIntegrationOptionLabel }}</span>
                  </div>
                  <div>
                    <strong>{{ formFields.webhookUrl.label }}:</strong>
                    <img class="inline-icon" :src="selectedActionOption.iconUrl" />
                    <span class="break-all">{{ formFields.webhookUrl.value }}</span>
                  </div>
                </template>

              </div>

            </div>
          </template>
        </div>

        <div class="step" ref="step-5">
          <div class="step-header">
            <span class="step-number">5</span>
            <h5>Test Action</h5>
            <button
              type="button"
              @click="currentStep = 5"
              class="link edit-step-link"
              v-if="currentStep !== 5 && stepSubmissions[4] && selectedActionOption.apiRecord.slug !== 'dispatch-monitor'"
            >
              Retry
            </button>
            <span class="flex-grow" />
            <CheckIcon class="check-icon" v-if="currentStep !== 5 && stepSubmissions[4]" />
          </div>
          <template v-if="currentStep === 5">
            <div class="step-body">
              <template v-if="selectedActionOption.apiRecord.slug === 'email'">
                <template v-if="testActionStatus === 'start'">
                  <p>
                    Let's make sure we have the right email address.
                  </p>
                </template>
                <template v-if="testActionStatus === 'pending'">
                  <div class="text-center">
                    <LoadingIcon />
                  </div>
                </template>
                <template v-if="testActionStatus === 'success'">
                  <div class="test-action-result-header">
                    <h4>Email sent</h4>
                    <CheckIcon class="text-neutral-500" />
                  </div>
                  <p>
                    We sent an email to the address you specified.
                    Please check your email and make sure you received it.
                    <br>
                    <br>
                    If you don't see it, check your spam folder. If you still
                    don't see it, please double check that you entered the
                    correct address:
                  </p>
                  <p class="test-action-result">
                    <strong>Email sent to:</strong> {{ formFields.email.value }}
                    <br>
                    <br>
                    <strong>Email sent at:</strong> {{ testActionSentAt }}
                  </p>
                </template>
                <template v-if="testActionStatus === 'error'">
                  <div class="test-action-result-header">
                    <h4>Oops!</h4>
                    <ErrorIcon class="text-warning-500" />
                  </div>
                  <p>
                    We were not able to send an email to the address you
                    provided.
                  </p>
                  <p class="test-action-result">
                    Please go back to Action Details and enter a valid email
                    address, then try the test again.
                  </p>
                </template>
              </template>
              <template v-else-if="selectedActionOption.apiRecord.slug === 'dispatch-monitor'">
                <p class="mb-3">
                  No test needed for Dispatch Monitor!
                  <br>
                  <br>
                  When your Trigger conditions are met, you’ll see it in the
                  Dispatch Monitor tab:
                </p>
                <img src="@/assets/images/dispatch-monitor.png" />
              </template>
              <template v-else>
                <template v-if="testActionStatus === 'start'">
                  <p>
                    <template v-if="selectedActionOption.apiRecord.slug === 'telegram'">
                      <template v-if="selectedTelegramActionEventOption.value === 'private'">
                        Let's make sure Dispatch can send messages to that Telegram account.
                      </template>
                      <template v-else>
                        Let's make sure Dispatch can send messages to that Telegram group.
                      </template>
                    </template>
                    <template v-else-if="selectedActionOption.apiRecord.slug === 'discord'">
                      <template v-if="selectedDiscordActionEventOption.value === 'dm-channel'">
                        Let's make sure Dispatch can send messages to that Discord user.
                      </template>
                      <template v-else>
                        Let's make sure Dispatch can send messages to that Discord server channel.
                      </template>
                    </template>
                    <template v-else-if="selectedActionOption.apiRecord.slug === 'webhook'">
                      Let's make sure Dispatch can send data to that webhook.
                    </template>
                    <template v-else>
                      Let's make sure Dispatch can send you a message.
                    </template>
                  </p>
                </template>
                <template v-if="testActionStatus === 'pending'">
                  <div class="text-center">
                    <LoadingIcon />
                  </div>
                </template>
                <template v-if="testActionStatus === 'success'">
                  <div class="test-action-result-header">
                    <h4>Success!</h4>
                    <CheckIcon class="text-success-500" />
                  </div>
                  <p>
                    <template v-if="selectedActionOption.apiRecord.slug === 'webhook'">
                      We were able to send data to your webhook.
                    </template>
                    <template v-else>
                      We were able to send a message to {{ selectedActionOption.apiRecord.name }}.
                    </template>
                  </p>
                  <p class="test-action-result">
                    <template v-if="selectedActionOption.apiRecord.slug === 'telegram'">
                      <strong>Message sent to:</strong> {{ selectedTelegramIntegrationLabel }}
                    </template>
                    <template v-else-if="selectedActionOption.apiRecord.slug === 'discord'">
                      <strong>Message sent to:</strong> {{ selectedDiscordServerChannelLabel || selectedDiscordIntegrationLabel }}
                    </template>
                    <template v-else-if="selectedActionOption.apiRecord.slug === 'webhook'">
                      <strong>JSON payload sent to:</strong> {{ selectedWebhookIntegrationOptionLabel }} <span class="break-all">({{ selectedWebhook.defaultOutput }})</span>
                    </template>
                    <br>
                    <br>
                    <template v-if="selectedActionOption.apiRecord.slug === 'webhook'">
                      <strong>JSON payload sent at:</strong> {{ testActionSentAt }}
                    </template>
                    <template v-else>
                      <strong>Message sent at:</strong> {{ testActionSentAt }}
                    </template>
                  </p>
                </template>
                <template v-if="testActionStatus === 'error'">
                  <div class="test-action-result-header">
                    <h4>Oops!</h4>
                    <ErrorIcon class="text-warning-500" />
                  </div>
                  <p>
                    <template v-if="selectedActionOption.apiRecord.slug === 'webhook'">
                      We were not able to send a JSON payload to your webhook.
                    </template>
                    <template v-else>
                      We were not able to send a message to {{ selectedActionOption.apiRecord.name }}.
                    </template>
                  </p>
                  <p class="test-action-result">
                    <template v-if="selectedActionOption.apiRecord.slug === 'webhook'">
                      Please make sure your webhook URL can accept HTTP POST
                      requests with the <pre>Content-Type</pre> header set to
                      <pre>application/json</pre>. If your webhook is not up
                      and running yet, you can simply skip this step.
                    </template>
                    <template v-else>
                      Please make sure you have added {{ $store.state.app.dispatchBotName }} to your
                      <template v-if="selectedActionOption.apiRecord.slug === 'telegram'">
                        Telegram chat or group chat
                      </template>
                      <template v-else-if="selectedActionOption.apiRecord.slug === 'discord'">
                        Discord server or direct message with the requested permissions
                      </template>
                      and try the test again.
                    </template>
                  </p>
                </template>
              </template>
            </div>
            <div class="mt-4 step-buttons">
              <button
                type="button"
                class="secondary small"
                @click="currentStep -= 1"
                :disabled="testActionStatus === 'pending'"
              >
                Back
              </button>
              <button
                type="button"
                class="small tertiary"
                @click="submitCurrentStep()"
                :disabled="testActionStatus === 'pending'"
                v-if="(testActionStatus === 'start' && selectedActionOption.apiRecord.slug !== 'dispatch-monitor') || (testActionStatus !== 'success' && selectedActionOption.apiRecord.slug == 'webhook')"
              >
                Skip this step
              </button>
              <button
                type="button"
                class="small"
                @click="testActionSubmitAction()"
                :disabled="testActionStatus === 'pending'"
              >
                <template v-if="testActionStatus === 'success'">
                  Continue
                </template>
                <template v-else-if="testActionStatus === 'error'">
                  Edit Action details
                </template>
                <template v-else>
                  <template v-if="selectedActionOption.apiRecord.slug === 'email'">
                    Send a test email
                  </template>
                  <template v-else-if="selectedActionOption.apiRecord.slug === 'telegram' || selectedActionOption.apiRecord.slug === 'discord'">
                    Send a test message
                  </template>
                  <template v-else-if="selectedActionOption.apiRecord.slug === 'webhook'">
                    Send a test JSON payload
                  </template>
                  <template v-else>
                    Continue
                  </template>
                </template>
              </button>
            </div>
          </template>
        </div>

        <div class="step" ref="step-6">
          <div class="step-header">
            <span class="step-number">6</span>
            <h5>Confirm Patch name</h5>
          </div>
          <template v-if="currentStep === 6">
            <div class="step-body">
              <FormInput :formName="formName" fieldName="name" />
            </div>
            <div class="step-buttons">
              <button
                type="button"
                class="secondary small"
                @click="currentStep -= 1"
              >
                Back
              </button>
              <button
                type="submit"
                class="small"
                :disabled="!isStep6Valid"
              >
                {{ arePatchesPseudoPaused ? 'Save Patch' : 'Turn Patch on' }}
              </button>
            </div>
          </template>
        </div>

      </form>

    </div>
  </main>

  <Footer />

</template>

<script>

  import { ref } from 'vue'
  import { mapState, mapGetters } from 'vuex'

  import useABI from '@/composables/useABI'
  import useForm from '@/composables/useForm'
  import useFilters from '@/composables/useFilters'
  import useBeforeUnload from '@/composables/useBeforeUnload'
  import useCreateAccountIntegration from '@/composables/useCreateAccountIntegration'

  import Header from '@/components/page/Header.vue'
  import Footer from '@/components/page/Footer.vue'

  import InfoIcon from '@/assets/icons/info.svg'
  import ArrowIcon from '@/assets/icons/arrow.svg'
  import ErrorIcon from '@/assets/icons/error.svg'
  import CheckIcon from '@/assets/icons/check-circle.svg'
  import SmartContractIcon from '@/assets/icons/smart-contract.svg'

  import FormInput from '@/components/forms/FormInput.vue'
  import FormSelect from '@/components/forms/FormSelect.vue'
  import FormCheckbox from '@/components/forms/FormCheckbox.vue'
  import RichChecklist from '@/components/forms/RichChecklist.vue'

  import ContractABIEntrySignature from '@/components/etc/ContractABIEntrySignature.vue'

  import Tooltip from '@/components/utils/Tooltip.vue'
  import LoadingIcon from '@/components/utils/LoadingIcon.vue'

  export default {
    inject: ['$mixpanel'],
    components: {
      Header,
      Footer,
      Tooltip,
      InfoIcon,
      ArrowIcon,
      ErrorIcon,
      CheckIcon,
      FormInput,
      FormSelect,
      LoadingIcon,
      FormCheckbox,
      RichChecklist,
      SmartContractIcon,
      ContractABIEntrySignature,
    },
    setup() {

      // data
      const currentStep = ref(1)
      const startTime = ref(null)
      const previousRoute = ref(null)
      const formName = 'createPatchForm'
      const areUserContractABIEntriesLoading = ref(false)
      const stepSubmissions = ref([false, false, false, false, false, false])

      // @NOTE: this is a map of templateValues keys to booleans and indicates
      //  which template values have been applied... we need to keep track of
      //  this for values like discordChannelId that can't be set on page load
      //  since the options are retrieved asynchronously via an api call
      const appliedTemplateValuesMap = ref({})

      const testActionSentAt = ref(null)
      const testActionStatus = ref('start') // start, pending, success, or error

      // composables
      const { getABIEntrySignaturePieces } = useABI()
      const { titleCase, formatTimestamp, truncateAddress } = useFilters()
      const { openCreateAccountIntegrationModal } = useCreateAccountIntegration()
      const { form, templateValues, hadTemplateValuesApplied } = useForm({ formName })
      const { shouldPreventRouteChange } = useBeforeUnload({ message: 'Are you sure you want to leave this page without creating your Patch?' })

      // if there were no pre-saved form values, then allow them to navigate
      //  away from the page (at least until the trigger ID has been set)
      if (!hadTemplateValuesApplied) {
        shouldPreventRouteChange.value = false
      }

      return Object.assign({
        form,
        formName,
        templateValues,

        startTime,
        currentStep,
        previousRoute,
        stepSubmissions,
        testActionStatus,
        testActionSentAt,
        appliedTemplateValuesMap,
        areUserContractABIEntriesLoading,

        titleCase,
        formatTimestamp,
        truncateAddress,
        getABIEntrySignaturePieces,
        openCreateAccountIntegrationModal,

        shouldPreventRouteChange,

      })

    },
    computed: {
      ...mapState('user', ['user', 'userFlags', 'accountIntegrations']),
      ...mapState('app', ['isAPIReadOnly', 'actionSettingsSlugMap', 'triggerSettingsSlugMap']),
      ...mapGetters('user', ['arePatchesPseudoPaused', 'getUserContractOptionByContractId']),
      previousRouteName() {
        if (
          !this.previousRoute
          || !this.previousRoute.name
          || this.previousRoute.name === 'Redirect'
        ) return 'Dashboard'
        return this.previousRoute.name.replace(/(\S)([A-Z])/g, '$1 $2')
      },
      previousRoutePath() {
        if (
          !this.previousRoute
          || !this.previousRoute.path
          || this.previousRoute.path === '/'
          || this.previousRoute.name === 'Redirect'
        ) return '/dashboard'
        return this.previousRoute.path
      },
      formFields() {
        return this.form.fields
      },
      isBalanceChangeTrigger() {
        if (!this.selectedTriggerOption) return null
        return this.selectedTriggerOption.apiRecord.type === 'balance-change'
      },
      isV2DexTrigger() {
        if (!this.selectedTriggerOption) return null
        return this.selectedTriggerOption.apiRecord.type === 'v2-dex'
      },
      isV3DexTrigger() {
        if (!this.selectedTriggerOption) return null
        return this.selectedTriggerOption.apiRecord.type === 'v3-dex'
      },
      isNFTTrigger() {
        if (!this.selectedTriggerOption) return null
        return this.selectedTriggerOption.apiRecord.type === 'nft'
      },
      isUserContractTrigger() {
        if (!this.selectedTriggerOption) return null
        return this.selectedTriggerOption.apiRecord.type === 'user-contract'
      },
      shouldShowImplementationLabels() {
        return (
          this.formFields.userContractProxyEvents.options.length !== 0
          || this.formFields.userContractProxyFunctions.options.length !== 0
        )
      },
      selectedTriggerOption() {

        if (!this.formFields.triggerId.value) return null

        const selectedOption = this.formFields.triggerId.options.find((option) => {
          return option.value === this.formFields.triggerId.value
        })

        if (!selectedOption) return null

        return selectedOption

      },
      selectedActionOption() {

        if (!this.formFields.actionId.value) return null

        const selectedOption = this.formFields.actionId.options.find((option) => {
          return option.value === this.formFields.actionId.value
        })

        if (!selectedOption) return null

        return selectedOption

      },
      selectedNetworkOption() {

        if (!this.formFields.networkId.value) return null

        const selectedOption = this.formFields.networkId.options.find((option) => {
          return option.value === this.formFields.networkId.value
        })

        if (!selectedOption) return null

        return selectedOption

      },
      selectedContractOption() {

        if (!this.formFields.contractId.value) return null

        const selectedOption = this.formFields.contractId.options.find((option) => {
          return option.value === this.formFields.contractId.value
        })

        if (!selectedOption) return null

        return selectedOption

      },
      selectedUserContractOption() {

        if (!this.formFields.userContractId.value) return null

        const selectedOption = this.formFields.userContractId.options.find((option) => {
          return option.value === this.formFields.userContractId.value
        })

        if (!selectedOption) return null

        return selectedOption

      },
      selectedUserContractNickname() {
        if (!this.selectedUserContractOption) return null
        return (this.getUserContractOptionByContractId(this.selectedUserContractOption.apiRecord.id) || {}).label
      },
      selectedTelegramActionEventOption() {

        if (!this.formFields.telegramActionEvent.value) return null

        const selectedOption = this.formFields.telegramActionEvent.options.find((option) => {
          return option.value === this.formFields.telegramActionEvent.value
        })

        if (!selectedOption) return null

        return selectedOption

      },
      selectedTelegramIntegrationOption() {

        if (!this.formFields.telegramAccountIntegrationId.value) return null

        const selectedOption = this.formFields.telegramAccountIntegrationId.options.find((option) => {
          return option.value === this.formFields.telegramAccountIntegrationId.value
        })

        if (!selectedOption) return null

        return selectedOption

      },
      selectedDiscordActionEventOption() {

        if (!this.formFields.discordActionEvent.value) return null

        const selectedOption = this.formFields.discordActionEvent.options.find((option) => {
          return option.value === this.formFields.discordActionEvent.value
        })

        if (!selectedOption) return null

        return selectedOption

      },
      selectedDiscordIntegrationOption() {

        if (!this.formFields.discordAccountIntegrationId.value) return null

        const selectedOption = this.formFields.discordAccountIntegrationId.options.find((option) => {
          return option.value === this.formFields.discordAccountIntegrationId.value
        })

        if (!selectedOption) return null

        return selectedOption

      },
      selectedDiscordServerChannelOption() {

        if (!this.formFields.discordChannelId.value) return null

        const selectedOption = this.formFields.discordChannelId.options.find((option) => {
          return option.value === this.formFields.discordChannelId.value
        })

        if (!selectedOption) return null

        return selectedOption

      },
      selectedWebhookIntegrationOption() {

        if (!this.formFields.webhookAccountIntegrationId.value) return null

        const selectedOption = this.formFields.webhookAccountIntegrationId.options.find((option) => {
          return option.value === this.formFields.webhookAccountIntegrationId.value
        })

        if (!selectedOption) return null

        return selectedOption

      },
      selectedTriggerOptionLabel() {
        if (!this.selectedTriggerOption) return null
        return this.selectedTriggerOption.label
      },
      selectedActionOptionLabel() {
        if (!this.selectedActionOption) return null
        return this.selectedActionOption.label
      },
      selectedTelegramActionEventLabel() {
        if (!this.selectedTelegramActionEventOption) return null
        return this.selectedTelegramActionEventOption.label
      },
      selectedTelegramIntegrationLabel() {
        if (!this.selectedTelegramIntegrationOption) return null
        return this.selectedTelegramIntegrationOption.label
      },
      selectedDiscordActionEventLabel() {
        if (!this.selectedDiscordActionEventOption) return null
        return this.selectedDiscordActionEventOption.label
      },
      selectedDiscordIntegrationLabel() {
        if (!this.selectedDiscordIntegrationOption) return null
        return this.selectedDiscordIntegrationOption.label
      },
      selectedWebhookIntegrationOptionLabel() {
        if (!this.selectedWebhookIntegrationOption) return null
        return this.selectedWebhookIntegrationOption.label
      },
      selectedWebhook() {

        if (!this.selectedWebhookIntegrationOption) return null

        const selectedWebhook = this.accountIntegrations.find((accountIntegration) => {
          return accountIntegration.id === this.selectedWebhookIntegrationOption.value
        })

        if (!selectedWebhook) return null

        return selectedWebhook

      },
      selectedDiscordServerChannelLabel() {
        if (!this.selectedDiscordServerChannelOption) return null
        return this.selectedDiscordServerChannelOption.label
      },
      addressBalanceChangeThresholdDirectionCombined() {
        // sort the array first so that "above" always comes before "below",
        //  since "below or above" sounds kind of weird
        const sortedDirections = Array.from(this.formFields.addressBalanceChangeThresholdDirection.value).sort()
        return sortedDirections.join(' or ')
      },
      // @NOTE: this may need to be updated when new trigger types are added
      networkPlaceholder() {

        if (!this.selectedTriggerOption) return null

        switch (this.selectedTriggerOption.apiRecord.type) {

          case 'balance-change':
            return 'Select the network the token is on'

          case 'v2-dex':
          case 'v3-dex':
            return 'Select the network for the DEX you wish to monitor'

          case 'nft':
            return 'Select the network the NFT is on'

          case 'user-contract':
            return 'Select the network the contract is on'

          default:
            return null
        }

      },
      // @NOTE: this may need to be updated when new trigger types are added
      contractLabel() {

        if (!this.selectedTriggerOption) return null

        switch (this.selectedTriggerOption.apiRecord.type) {

          case 'balance-change':
            return 'Token'

          case 'nft':
            return 'NFT collection'

          // @NOTE: user-contract type triggers actually use a different form
          //  field for contract (userContractId) and dex triggers don't use the
          //  field at all
          default:
            return null
        }

      },
      // @NOTE: this may need to be updated when new trigger types are added
      contractPlaceholder() {

        if (!this.selectedTriggerOption) return null

        switch (this.selectedTriggerOption.apiRecord.type) {

          case 'balance-change':
            return 'Select a token to monitor'

          case 'nft':
            return 'Select the NFT collection you wish to monitor'

          // @NOTE: user-contract type triggers actually use a different form
          //  field for contract (userContractId) and dex triggers don't use the
          //  field at all
          default:
            return null
        }

      },
      isContractIdValid() {
        return (
          !!this.selectedNetworkOption
          && !!this.formFields.contractId.value
        )
      },
      isUserContractIdValid() {
        return (
          !!this.selectedNetworkOption
          && !!this.formFields.userContractId.value
        )
      },
      isAddressBalanceChangeAddressListValid() {

        // @NOTE: we can't ONLY use the isFieldValid getter here because the
        //  field isn't technically required
        //
        // since isFieldValid will return true for optional fields that are
        //  empty, we need to also make sure the field isn't empty
        const hasAtLeastOneValidSubmittedEntry = (
          this.formFields.addressBalanceChangeAddressList.value.length !== 0
          && this.$store.getters['forms/isFieldValid'](this.formName, 'addressBalanceChangeAddressList')
        )

        // as a special request from J, we want to have the step "submittable"
        //  if the user has at least one valid address in the displayValue (even
        //  if they have not actually committed that value)
        //
        // fortunately we already have the isAtLeastOneMultiEntryValid method on
        //  the form field, so we can use that here to know if the displayValue
        //  contains a valid address and pre-emptively consider the field valid
        //
        // @NOTE: there is a crucial second step to this in the currentStep
        //  watcher; the form field needs the displayValue applied to the actual
        //  field value so we need to call the FormInput component's
        //  addMultiValues() method when the step is submitted
        const isAtLeastOneMultiEntryValid = (
          typeof this.formFields.addressBalanceChangeAddressList.isAtLeastOneMultiEntryValid === 'function'
          && this.formFields.addressBalanceChangeAddressList.isAtLeastOneMultiEntryValid(this.formFields.addressBalanceChangeAddressList.displayValue)
        )

        return hasAtLeastOneValidSubmittedEntry || isAtLeastOneMultiEntryValid

      },
      isAddressBalanceChangeThresholdValid() {
        return !Number.isNaN(Number.parseInt(this.formFields.addressBalanceChangeThreshold.value, 10))
      },
      isStep1Valid() {
        return !!this.formFields.triggerId.value
      },
      isStep2Valid() {

        if (!this.selectedTriggerOption) return false

        switch (this.selectedTriggerOption.apiRecord.slug) {

          case 'address-balance-change':
            return (
              this.isContractIdValid
              && !!this.formFields.networkId.value
              && this.isAddressBalanceChangeAddressListValid
            )

          case 'balance-threshold-reached': {
            return (
              this.isContractIdValid
              && !!this.formFields.networkId.value
              && this.isAddressBalanceChangeAddressListValid
              && !!this.formFields.addressBalanceChangeThreshold.value
              && this.formFields.addressBalanceChangeThresholdDirection.value.length !== 0
            )
          }

          case 'nft-collection-items-transferred':
            return (
              this.isContractIdValid
              && !!this.formFields.networkId.value
            )

          case 'smart-contract-activity':
            return (
              this.isUserContractIdValid
              && !!this.formFields.networkId.value
              && (
                this.formFields.userContractProxyEvents.value.length !== 0
                || this.formFields.userContractProxyFunctions.value.length !== 0
                || this.formFields.userContractImplementationEvents.value.length !== 0
                || this.formFields.userContractImplementationFunctions.value.length !== 0
              )
            )

          // @NOTE: since the only field for these trigger types is optional, step 2 is always valid
          case 'new-elk-listing':
          case 'new-pangolin-listing':
          case 'new-quickswap-listing':
          case 'new-sushiswap-listing':
          case 'new-trader-joe-listing':
          case 'new-uniswap-v2-listing':
          case 'new-uniswap-v3-listing':
          case 'new-pancakeswap-listing':
            return true

          default:
            return false
        }
      },
      isStep3Valid() {
        return !!this.formFields.actionId.value
      },
      isStep4Valid() {

        if (!this.selectedActionOption) return false

        switch (this.selectedActionOption.apiRecord.slug) {

          case 'email':
            return (
              !!this.formFields.email.value
              && this.$store.getters['forms/isFieldValid'](this.formName, 'email')
            )

          case 'telegram':
            return (
              !!this.formFields.telegramActionEvent.value
              && !!this.formFields.telegramAccountIntegrationId.value
              && this.formFields.telegramAccountIntegrationId.value !== 'add-new-account'
            )

          case 'discord':
            return (
              !!this.formFields.discordActionEvent.value
              && !!this.formFields.discordAccountIntegrationId.value
              && this.formFields.discordAccountIntegrationId.value !== 'add-new-account'
              && (this.selectedDiscordActionEventOption.value !== 'server-channel' || !!this.formFields.discordChannelId.value)
            )

          case 'dispatch-monitor':
            return true

          case 'webhook':
            return (
              !!this.formFields.webhookAccountIntegrationId.value
            )

          default: return false
        }
      },
      isStep5Valid() {
        return true // @NOTE: step 5 does not use this, it's always valid since the step is optional
      },
      isStep6Valid() {
        return !!this.formFields.name.value
      },
    },
    watch: {
      selectedUserContractOption(newValue, oldValue) {

        if (!newValue) return

        this.resetAllUserContractFields(false)

        this.$store.commit('forms/SET_FIELD_VALUE', {
          formName: this.formName,
          fieldName: 'userContractAddress',
          newValue: newValue.apiRecord.address,
        })

        this.$store.commit('forms/SET_FIELD_VALUE', {
          formName: this.formName,
          fieldName: 'userContractType',
          newValue: newValue.apiRecord.type,
        })

        this.$store.commit('forms/SET_FIELD_OPTIONS', {
          formName: this.formName,
          fieldName: 'userContractType',
          newOptions: [
            {
              id: newValue.apiRecord.type,
              value: newValue.apiRecord.type,
              label: newValue.apiRecord.type === 'other' ? this.titleCase(newValue.apiRecord.type) : newValue.apiRecord.type.toUpperCase(),
              iconUrl: this.selectedNetworkOption ? this.selectedNetworkOption.apiRecord.iconUrl : null,
            },
          ],
        })

        this.areUserContractABIEntriesLoading = true

        Promise
          .all([
            this.getContractABIEntryOptions(newValue.apiRecord.id, 'events'),
            this.getContractABIEntryOptions(newValue.apiRecord.id, 'functions'),
          ])
          .then(([
            [proxyEventOptions, implementationEventOptions],
            [proxyFunctionOptions, implementationFunctionOptions],
          ]) => {

            this.$store.commit('forms/SET_FIELD_OPTIONS', {
              formName: this.formName,
              fieldName: 'userContractProxyEvents',
              newOptions: proxyEventOptions,
            })

            this.$store.commit('forms/SET_FIELD_OPTIONS', {
              formName: this.formName,
              fieldName: 'userContractImplementationEvents',
              newOptions: implementationEventOptions,
            })

            this.$store.commit('forms/SET_FIELD_OPTIONS', {
              formName: this.formName,
              fieldName: 'userContractProxyFunctions',
              newOptions: proxyFunctionOptions,
            })

            this.$store.commit('forms/SET_FIELD_OPTIONS', {
              formName: this.formName,
              fieldName: 'userContractImplementationFunctions',
              newOptions: implementationFunctionOptions,
            })

          })
          .catch((error) => {
            this.resetAllUserContractFields()
          })
          .finally(() => {
            this.areUserContractABIEntriesLoading = false
          })

      },
      currentStep(newValue, oldValue) {

        // scroll the current step into view
        this.scrollToStep(newValue)

        // if we're submitting the second step, auto-add any remaining values in
        //  the addressBalanceChangeAddressList displayValue that haven't been
        //  committed to the actual form value
        if (newValue === 3 && this.$refs.addressBalanceChangeAddressList) {
          this.$refs.addressBalanceChangeAddressList.addMultiValues()
        }

        if (
          newValue === 3
          && !this.userFlags.hasAnsweredNftMediaCaching
          && this.selectedTriggerOption.apiRecord.slug === 'smart-contract-activity'
          && this.getUserContractOptionByContractId(this.selectedUserContractOption.apiRecord.id)
          && (this.selectedUserContractOption.apiRecord.type === 'erc-721' || this.selectedUserContractOption.apiRecord.type === 'erc-1155')
        ) {
          this.$store.dispatch('modals/OPEN_MODAL', {
            name: 'NFTMediaCachingModal',
          })
        }

        if (newValue === 4 && this.selectedActionOption.apiRecord.slug === 'email' && !this.formFields.email.value) {
          this.$store.dispatch('forms/UPDATE_FIELD', {
            fieldName: 'email',
            formName: this.formName,
            newValue: this.user.email,
            newPlaceholder: this.user.email,
          })
        }

        if (newValue === 5) {
          this.testActionStatus = 'start'
        }

        if (newValue === 6) {

          const suggestedPatchName = this.getSuggestedPatchName()

          this.$store.dispatch('forms/UPDATE_FIELD', {
            fieldName: 'name',
            formName: this.formName,
            newValue: suggestedPatchName,
            newPlaceholder: suggestedPatchName,
          })

        }

      },
      selectedTriggerOption(newValue, oldValue) {

        if (!newValue) return

        const newNetworkOptions = this.$store.state.app.triggerNetworkOptionsSlugMap[newValue.apiRecord.slug]
        const newNetworkOptionValue = newNetworkOptions.length === 1
          ? newNetworkOptions[0].value
          : ''

        this.$store.commit('forms/SET_FIELD_VALUE', {
          fieldName: 'networkId',
          formName: this.formName,
          newValue: '',
        })

        this.$store.commit('forms/SET_FIELD_OPTIONS', {
          fieldName: 'networkId',
          formName: this.formName,
          newOptions: newNetworkOptions,
        })

        this.$store.commit('forms/SET_FIELD_DISABLED', {
          fieldName: 'networkId',
          formName: this.formName,
          newValue: newNetworkOptions.length === 1,
        })

        this.$store.dispatch('forms/UPDATE_FIELD', {
          fieldName: 'networkId',
          formName: this.formName,
          newValue: newNetworkOptionValue,
          newPlaceholder: this.networkPlaceholder,
        })

        this.$store.dispatch('forms/UPDATE_FIELD', {
          newValue: '',
          fieldName: 'contractId',
          formName: this.formName,
          newLabel: this.contractLabel,
          newPlaceholder: this.contractPlaceholder,
        })

        this.resetAllUserContractFields()

        this.onSelectedNetworkOptionChange(newNetworkOptions.length === 1 ? newNetworkOptions[0] : null)

      },
      selectedTriggerOptionLabel(newValue, oldValue) {
        this.stepSubmissions[1] = false
        this.shouldPreventRouteChange = true
      },
      selectedActionOptionLabel(newValue, oldValue) {
        this.stepSubmissions[3] = false
        this.stepSubmissions[4] = false
      },
      'formFields.email.value': function emailValue(newValue, oldValue) {
        this.stepSubmissions[3] = false
        this.stepSubmissions[4] = false
      },
      selectedTelegramActionEventLabel(newValue, oldValue) {
        this.stepSubmissions[3] = false
        this.stepSubmissions[4] = false
      },
      selectedTelegramIntegrationLabel(newValue, oldValue) {
        this.stepSubmissions[3] = false
        this.stepSubmissions[4] = false
      },
      selectedDiscordActionEventLabel(newValue, oldValue) {
        this.stepSubmissions[3] = false
        this.stepSubmissions[4] = false
      },
      selectedDiscordIntegrationLabel(newValue, oldValue) {
        this.stepSubmissions[3] = false
        this.stepSubmissions[4] = false
      },
      selectedDiscordServerChannelLabel(newValue, oldValue) {
        this.stepSubmissions[3] = false
        this.stepSubmissions[4] = false
      },
      selectedWebhookIntegrationOptionLabel(newValue, oldValue) {
        this.stepSubmissions[3] = false
        this.stepSubmissions[4] = false
      },
      isContractIdValid(newValue, oldValue) {
        this.$store.commit('forms/SET_FIELD_DISABLED', {
          fieldName: 'addressBalanceChangeAddressList',
          formName: this.formName,
          newValue: !newValue,
        })
      },
      isAddressBalanceChangeAddressListValid(newValue, oldValue) {
        this.$store.commit('forms/SET_FIELD_DISABLED', {
          fieldName: 'addressBalanceChangeThreshold',
          formName: this.formName,
          newValue: !newValue,
        })
        this.$store.commit('forms/SET_FIELD_DISABLED', {
          fieldName: 'addressBalanceChangeThresholdDirection',
          formName: this.formName,
          newValue: !newValue,
        })
      },
      isAddressBalanceChangeThresholdValid(newValue, oldValue) {
        this.$store.commit('forms/SET_FIELD_DISABLED', {
          fieldName: 'addressBalanceChangeThresholdDirection',
          formName: this.formName,
          newValue: !newValue,
        })
      },
      selectedNetworkOption(newValue, oldValue) {
        // @NOTE: this logic must be in a separate method so that it can be
        //  called from the selectedTriggerOption watcher too
        this.onSelectedNetworkOptionChange(newValue, oldValue)
      },
      selectedTelegramActionEventOption(newValue, oldValue) {

        if (!newValue || !newValue.value) return

        this.$store.commit('forms/SET_FIELD_DISABLED', {
          newValue: false,
          formName: this.formName,
          fieldName: 'telegramAccountIntegrationId',
        })

        this.$store.commit('forms/SET_FIELD_VALUE', {
          newValue: '',
          formName: this.formName,
          fieldName: 'telegramAccountIntegrationId',
        })

        this.$store.dispatch('forms/REFRESH_TELEGRAM_DESTINATION_OPTIONS')

      },
      selectedTelegramIntegrationOption(newValue, oldValue) {

        if (!newValue || !newValue.value) return null

        if (newValue.value === 'add-new-account') {

          this.$store.commit('forms/SET_FIELD_VALUE', {
            fieldName: 'telegramAccountIntegrationId',
            formName: this.formName,
            newValue: '',
          })

          const telegramChatType = this.selectedTelegramActionEventOption.value === 'private'
            ? 'private'
            : 'group'

          return this.openCreateAccountIntegrationModal(`telegram-${telegramChatType}`)
            .catch(() => {
              // do nothing, a toast will be triggered from inside
              //  openCreateAccountIntegrationModal if there's an error
            })

        }

        return null

      },
      selectedDiscordActionEventOption(newValue, oldValue) {

        if (!newValue || !newValue.value) return

        this.$store.commit('forms/SET_FIELD_DISABLED', {
          newValue: false,
          formName: this.formName,
          fieldName: 'discordAccountIntegrationId',
        })
        this.$store.commit('forms/SET_FIELD_DISABLED', {
          newValue: true,
          formName: this.formName,
          fieldName: 'discordChannelId',
        })

        this.$store.commit('forms/SET_FIELD_VALUE', {
          newValue: '',
          formName: this.formName,
          fieldName: 'discordAccountIntegrationId',
        })
        this.$store.commit('forms/SET_FIELD_VALUE', {
          newValue: '',
          formName: this.formName,
          fieldName: 'discordChannelId',
        })

        this.$store.dispatch('forms/REFRESH_DISCORD_DESTINATION_OPTIONS')

      },
      selectedDiscordIntegrationOption(newValue, oldValue) {

        if (!newValue || !newValue.value) return null

        const discordChannelType = this.selectedDiscordActionEventOption.value === 'server-channel'
          ? 'server'
          : 'dm'

        if (newValue.value === 'add-new-account') {

          this.$store.commit('forms/SET_FIELD_VALUE', {
            fieldName: 'discordAccountIntegrationId',
            formName: this.formName,
            newValue: '',
          })

          return this.openCreateAccountIntegrationModal(`discord-${discordChannelType}`)
            .catch(() => {
              // do nothing, a toast will be triggered from inside
              //  openCreateAccountIntegrationModal if there's an error
            })

        }

        if (discordChannelType === 'dm') {

          // @NOTE: this also takes are of the case where the user is
          //  duplicating a Discord DM patch, since the initial
          //  discordAccountIntegrationId templateValue will fire this watcher
          //  and the associated DM channel ID stored in the account
          //  integration's defaultOutput field will be set here (no need to
          //  worry about the asynchronous loading of channels in this case
          //  since DM channels are dedicated account integrations unlike server
          //  channels)
          this.$store.commit('forms/SET_FIELD_VALUE', {
            fieldName: 'discordChannelId',
            formName: this.formName,
            newValue: this.selectedDiscordIntegrationOption.apiRecord.defaultOutput,
          })

          return null

        }

        this.$store.commit('forms/SET_FIELD_DISABLED', {
          fieldName: 'discordChannelId',
          formName: this.formName,
          newValue: true,
        })

        // @NOTE: we can't just always use the templateValue here since that
        //  value would be used even if the user changes the server option
        //  later, so instead we'll apply the template value on the first
        //  options update only
        const newDiscordChannelIdValue = this.templateValues.discordChannelId && !this.appliedTemplateValuesMap.discordChannelId
          ? this.templateValues.discordChannelId
          : ''

        return this.$store.dispatch('forms/REFRESH_DISCORD_SERVER_CHANNEL_OPTIONS', newDiscordChannelIdValue)
          .then(() => {

            if (this.templateValues.discordChannelId) {
              this.appliedTemplateValuesMap.discordChannelId = true
            }

            this.$store.commit('forms/SET_FIELD_DISABLED', {
              fieldName: 'discordChannelId',
              formName: this.formName,
              newValue: false,
            })

          })
          .catch((error) => {
            this.$store.commit('forms/SET_FIELD_VALUE', {
              fieldName: 'discordAccountIntegrationId',
              formName: this.formName,
              newValue: '',
            })
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: 'Could not refresh Discord server channels! Please try again later.',
              type: 'error',
            })
          })

      },
      selectedWebhookIntegrationOption(newValue, oldValue) {

        if (!newValue || !newValue.value) return null

        if (newValue.value === 'add-new-account') {

          this.$store.commit('forms/SET_FIELD_VALUE', {
            fieldName: 'webhookAccountIntegrationId',
            formName: this.formName,
            newValue: '',
          })

          this.$store.commit('forms/SET_FIELD_VALUE', {
            newValue: '',
            formName: this.formName,
            fieldName: 'webhookUrl',
          })

          return this.openCreateAccountIntegrationModal('webhook')
            .catch(() => {
              // do nothing, a toast will be triggered from inside
              //  openCreateAccountIntegrationModal if there's an error
            })

        }

        this.$store.commit('forms/SET_FIELD_VALUE', {
          formName: this.formName,
          fieldName: 'webhookUrl',
          newValue: this.selectedWebhook ? this.selectedWebhook.defaultOutput : '',
        })

        return null

      },
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.previousRoute = from
      })
    },
    beforeUnmount() {
      if (this.shouldPreventRouteChange) {
        this.logMixpanelEvent('Patch Creation Abandoned')
      }
    },
    created() {

      // @NOTE: we can't just use the useForm() composable's applyTemplateValues
      //  method here since that doesn't guarantee fields will be populated in
      //  order (since Object.keys() doesn't), so instead we'll just set all the
      //  fields here manually so we can control the order in which they are
      //  populated
      //
      // this is necessary because the watchers above all need to react to
      //  things "in order" (i.e. as a user would fill them in), because
      //  certain fields are cleared out when others are set (e.g. contractId is
      //  cleared when networkId is changed)
      const fieldNamesInOrder = [
        'triggerId',
        'actionId',

        'networkId',
        'contractId',
        'userContractId',

        'addressBalanceChangeAddressList',
        'addressBalanceChangeThreshold',
        'addressBalanceChangeThresholdDirection',

        'userContractProxyEvents',
        'userContractProxyFunctions',
        'userContractImplementationEvents',
        'userContractImplementationFunctions',

        'email',

        'telegramActionEvent',
        'telegramAccountIntegrationId',

        // @NOTE: we can't set discordChannelId here like the other values
        //  because it's options are set asynchronously via an api call, which
        //  will reset the value at the same time
        //
        // instead, we'll keep track of which templateValues have been applied
        //  and apply discordChannelId after the API call is made
        'discordActionEvent',
        'discordAccountIntegrationId',
        // 'discordChannelId',

        'webhookAccountIntegrationId',
      ]

      fieldNamesInOrder.forEach((fieldName) => {

        const newValue = this.templateValues[fieldName]

        if (!newValue) return

        this.appliedTemplateValuesMap[fieldName] = true

        this.$store.commit('forms/SET_FIELD_VALUE', {
          formName: this.formName,
          fieldName,
          newValue,
        })

      })

      this.$store.commit('forms/SET_FIELD_DISABLED', { formName: this.formName, fieldName: 'triggerId', newValue: this.isAPIReadOnly })

      // if pre-set data was saved after clicking a patch template card,
      //  submitting the dashboardCreatePatchForm, duplicating a patch, etc,
      //  set the completed steps as "completed"
      //
      // @NOTE: these checks *all* must happen in order so the currentStep
      //  watcher runs for each change, otherwise the step summaries won't
      //  appear correct
      if (this.isStep1Valid) {

        this.currentStep = 2
        this.stepSubmissions[0] = true
        this.scrollToStep(2)

        if (this.isStep2Valid) {

          this.currentStep = 3
          this.stepSubmissions[1] = true
          this.scrollToStep(3)

          if (this.isStep3Valid) {
            this.currentStep = 4
            this.stepSubmissions[2] = true
            this.scrollToStep(4)
          }

        }

      }

    },
    mounted() {
      this.startTime = Date.now()
    },
    methods: {
      scrollToStep(stepNumber) {
        // @NOTE: we need to delay this by one tick so the content updates
        //  before the scroll happens, since the "next step" container will
        //  not have all of it's form fields inserted until currentStep
        //  changes
        //
        // the second $nextTick fixes an issue with calling scrollToStep from
        //  the created() hook, not sure why but it just doesn't work properly
        //  without the extra $nextTick
        this.$nextTick(() => {
          this.$nextTick(() => {
            const $stepElement = this.$refs[`step-${stepNumber}`]
            if ($stepElement) $stepElement.scrollIntoView({ behavior: 'smooth' })
          })
        })
      },
      submitCurrentStep() {
        this.stepSubmissions[this.currentStep - 1] = true
        this.currentStep += 1
      },
      submit($event) {

        if ($event) $event.preventDefault()

        if (this.isAPIReadOnly) return null

        // if they press enter while focused in a text input, the form might
        //  submit early; let's prevent that here, and as a bonus we can just
        //  submit the current step if it's valid
        if (
          (this.currentStep === 1 && this.isStep1Valid)
          || (this.currentStep === 2 && this.isStep2Valid)
          || (this.currentStep === 3 && this.isStep3Valid)
          || (this.currentStep === 4 && this.isStep4Valid)
          || (this.currentStep === 5 && this.isStep5Valid)
          // @NOTE: no condition for the last step, since that actually SHOULD submit the form
        ) {
          return this.submitCurrentStep()
        }

        // only submit if all steps are valid
        if (
          !this.isStep1Valid
          || !this.isStep2Valid
          || !this.isStep3Valid
          || !this.isStep4Valid
          || !this.isStep5Valid
          || !this.isStep6Valid
        ) return null

        this.$store.commit('modals/SET_ALLOW_MODAL_CLOSE', false)

        const onSuccess = (newPatch) => {
          this.shouldPreventRouteChange = false
          return Promise.all([
            this.logMixpanelEvent('Patch Creation Completed'),
            this.$store.dispatch('user/REFRESH_USER_PLAN_STATS'),
          ])
        }

        const onError = (error) => {
          // do nothing else, the modal will show the form submit error
          this.shouldPreventRouteChange = true
        }

        return this.$store.dispatch('modals/OPEN_MODAL', {
          name: 'PatchSaveModal',
          props: {
            onError,
            onSuccess,
          },
        })

      },
      getSuggestedPatchName() {

        if (!this.selectedTriggerOption || !this.selectedActionOption) return ''

        let suggestedPatchName = `${this.selectedActionOption.description} when `

        const truncatedAddress = this.truncateAddress(this.formFields.addressBalanceChangeAddressList.value[0])

        switch (this.selectedTriggerOption.apiRecord.slug) {

          case 'address-balance-change': {
            if (this.formFields.addressBalanceChangeAddressList.value.length === 1) {
              suggestedPatchName += `${this.selectedContractOption.apiRecord.symbol} balance changes (${truncatedAddress})`
            } else {
              suggestedPatchName += `${this.selectedContractOption.apiRecord.symbol} balance changes (multiple addresses)`
            }
            break
          }

          case 'balance-threshold-reached': {
            const direction = this.addressBalanceChangeThresholdDirectionCombined.replace('above', '>').replace('below', '<')
            suggestedPatchName += `${this.selectedContractOption.apiRecord.symbol} balance ${direction} ${this.formFields.addressBalanceChangeThreshold.value} (${truncatedAddress})`
            break
          }

          case 'new-elk-listing':
            suggestedPatchName += 'new pairs are added on Elk DEX'
            break

          case 'new-pangolin-listing':
            suggestedPatchName += 'new pairs are added on Pangolin DEX'
            break

          case 'new-quickswap-listing':
            suggestedPatchName += 'new pairs are added on QuickSwap DEX'
            break

          case 'new-sushiswap-listing':
            suggestedPatchName += 'new pools are added on SushiSwap DEX'
            break

          case 'new-trader-joe-listing':
            suggestedPatchName += 'new pairs are added on Trader Joe DEX'
            break

          case 'new-uniswap-v2-listing':
            suggestedPatchName += 'new pairs are added on Uniswap V2 DEX'
            break

          case 'new-uniswap-v3-listing':
            suggestedPatchName += 'new pools are added on Uniswap V3 DEX'
            break

          case 'new-pancakeswap-listing':
            suggestedPatchName += 'new pairs are added on PancakeSwap V2 DEX'
            break

          case 'nft-collection-items-transferred':
            suggestedPatchName += `${this.selectedContractOption.label} NFTs are transferred`
            break

          case 'smart-contract-activity': {

            const contractName = this.selectedUserContractOption.label

            const selectedEventNames = this.formFields.userContractProxyEvents.value
              .concat(this.formFields.userContractImplementationEvents.value)
              .map((abiEntrySignature) => {
                return abiEntrySignature.replace(/(.*?)\(.*\)/, '$1')
              })

            const selectedFunctionNames = this.formFields.userContractProxyFunctions.value
              .concat(this.formFields.userContractImplementationFunctions.value)
              .map((abiEntrySignature) => {
                return abiEntrySignature.replace(/(.*?)\(.*\)/, '$1')
              })

            const eventSnippet = `${selectedEventNames.join(', ')} ${selectedEventNames.length === 1 ? 'event is' : 'events are'} emitted`
            const functionSnippet = `${selectedFunctionNames.join(', ')} ${selectedFunctionNames.length === 1 ? 'function is' : 'functions are'} called`

            if (selectedEventNames.length !== 0 && selectedFunctionNames.length === 0) {
              suggestedPatchName += `${eventSnippet} by ${contractName}`

            } else if (selectedEventNames.length === 0 && selectedFunctionNames.length !== 0) {
              suggestedPatchName += `${functionSnippet} from ${contractName}`

            } else {
              suggestedPatchName += `${eventSnippet} or ${functionSnippet} from ${contractName}`
            }

            break

          }

          default: // do nothing
        }

        return suggestedPatchName

      },
      testActionSubmitAction() {

        if (this.testActionStatus === 'success') {
          this.submitCurrentStep()
          return
        }

        if (this.testActionStatus === 'error') {
          this.currentStep -= 1
          return
        }

        let testActionPromise = null

        const triggerId = this.formFields.triggerId.value

        switch (this.selectedActionOption.apiRecord.slug) {
          case 'email':
            testActionPromise = this.$store.state.api.dispatch
              .post('/validate/email', { email: this.formFields.email.value, triggerId })
            break

          case 'discord':
            testActionPromise = this.$store.state.api.dispatch
              .post(`/integrations/${this.formFields.discordAccountIntegrationId.value}/test`, { channel: this.formFields.discordChannelId.value, triggerId })
            break

          case 'telegram': {
            const accountIntegration = this.$store.getters['user/getAccountIntegrationById'](this.formFields.telegramAccountIntegrationId.value)
            testActionPromise = this.$store.state.api.dispatch
              .post(`/integrations/${accountIntegration.id}/test`, { channel: accountIntegration.defaultOutput, triggerId })
            break
          }

          case 'webhook':
            testActionPromise = this.$store.state.api.dispatch
              .post(`/integrations/${this.formFields.webhookAccountIntegrationId.value}/test`, { triggerId })
            break

          case 'dispatch-monitor':
            this.submitCurrentStep()
            return

          default:
            return // do nothing
        }

        this.testActionStatus = 'pending'
        this.testActionSentAt = this.formatTimestamp(new Date())

        testActionPromise
          .then(() => {
            this.testActionStatus = 'success'
          })
          .catch(() => {
            this.testActionStatus = 'error'
          })

      },
      logMixpanelEvent(eventName) {

        return this.$store.dispatch('forms/GET_FORM_DATA', this.formName)
          .then((formData) => {

            // do some array flattening since Mixpanel can't "reach" into
            //  arrays (also do some key renaming for Jialin)

            // @NOTE: we use JSON.parse() and JSON.stringify() to "deep clone"
            //  the object
            const flattendFormData = JSON.parse(JSON.stringify(formData))

            let numAddresses = 0

            delete flattendFormData.settings

            formData.settings.forEach((patchSetting) => {

              const setting = this.actionSettingsSlugMap[patchSetting.settingSlug] || this.triggerSettingsSlugMap[patchSetting.settingSlug]

              if (!setting) return

              const keyName = `${setting.type}Settings`

              flattendFormData[keyName] ||= {}
              flattendFormData[keyName][setting.slug] = Object.assign({}, patchSetting)

              // having a list of comma separated addresses isn't useful in
              //  Mixpanel, so we need to break it out into an array before
              //  logging the event so each indivdual address can be broken out
              //  and/or matched upon in reports
              if (setting.type === 'trigger' && setting.slug === 'address-list') {
                flattendFormData[keyName][setting.slug].value = flattendFormData[keyName][setting.slug].value.split(',')
                numAddresses = flattendFormData[keyName][setting.slug].value.length
              }

              // same thing applies to contract events and functions, but those
              //  need to be separated a bit differently since there are commas
              //  in the abi signatures
              if (
                setting.type === 'trigger'
                && (
                  setting.slug === 'proxy-event-list'
                  || setting.slug === 'proxy-function-list'
                  || setting.slug === 'implementation-event-list'
                  || setting.slug === 'implementation-function-list'
                )
              ) {
                flattendFormData[keyName][setting.slug].value = flattendFormData[keyName][setting.slug].value.split(/,(?! )/)
                numAddresses = flattendFormData[keyName][setting.slug].value.length
              }

            })

            const suggestedPatchName = this.getSuggestedPatchName()

            const mixpanelData = {
              numAddresses,
              formData: flattendFormData,

              currentStep: this.currentStep,
              entryPoint: this.previousRouteName,
              templateValues: this.templateValues,
              buttonClicked: this.$route.params.referrerLink,
              secondsElapsed: (Date.now() - this.startTime) / 1000,

              suggestedPatchName,
              isSuggestedPatchName: !!suggestedPatchName && this.formFields.name.value === suggestedPatchName,

              actionName: null,
              actionSlug: null,

              triggerName: null,
              triggerSlug: null,

              networkName: null,
              networkSlug: null,

              contractType: null,
              contractName: null,
              contractOwner: null,
              contractSymbol: null,
              contractAddress: null,
              contractNickname: null,
              contractImplementationAddress: null,
            }

            if (this.selectedActionOption) {
              mixpanelData.actionName = this.selectedActionOption.apiRecord.name
              mixpanelData.actionSlug = this.selectedActionOption.apiRecord.slug
            }

            if (this.selectedTriggerOption) {
              mixpanelData.triggerName = this.selectedTriggerOption.apiRecord.name
              mixpanelData.triggerSlug = this.selectedTriggerOption.apiRecord.slug
            }

            if (this.selectedNetworkOption) {
              mixpanelData.networkName = this.selectedNetworkOption.apiRecord.name
              mixpanelData.networkSlug = this.selectedNetworkOption.apiRecord.slug
            }

            if (this.selectedContractOption) {
              mixpanelData.contractType = this.selectedContractOption.apiRecord.type
              mixpanelData.contractName = this.selectedContractOption.apiRecord.name
              mixpanelData.contractOwner = this.selectedContractOption.apiRecord.owner
              mixpanelData.contractSymbol = this.selectedContractOption.apiRecord.symbol
              mixpanelData.contractAddress = this.selectedContractOption.apiRecord.address
              mixpanelData.contractImplementationAddress = this.selectedContractOption.apiRecord.implementationAddress
            }

            if (this.selectedUserContractOption) {
              mixpanelData.contractNickname = this.selectedUserContractNickname
              mixpanelData.contractType = this.selectedUserContractOption.apiRecord.type
              mixpanelData.contractName = this.selectedUserContractOption.apiRecord.name
              mixpanelData.contractOwner = this.selectedUserContractOption.apiRecord.owner
              mixpanelData.contractSymbol = this.selectedUserContractOption.apiRecord.symbol
              mixpanelData.contractAddress = this.selectedUserContractOption.apiRecord.address
              mixpanelData.contractImplementationAddress = this.selectedUserContractOption.apiRecord.implementationAddress
            }

            this.$mixpanel.onReady((mixpanel) => {
              mixpanel.track(eventName, mixpanelData)
            })

          })
          .catch(() => {
            // do nothing
          })
      },
      onSelectedNetworkOptionChange(newValue, oldValue) {

        if (!this.selectedTriggerOption) return

        this.$store.commit('forms/SET_FIELD_VALUE', {
          formName: this.formName,
          fieldName: 'contractId',
          newValue: '',
        })

        this.$store.commit('forms/SET_FIELD_VALUE', {
          formName: this.formName,
          fieldName: 'userContractId',
          newValue: '',
        })

        this.resetAllUserContractFields()

        if (!newValue) {

          this.$store.commit('forms/SET_FIELD_DISABLED', {
            formName: this.formName,
            fieldName: 'contractId',
            newValue: true,
          })

          this.$store.commit('forms/SET_FIELD_DISABLED', {
            formName: this.formName,
            fieldName: 'userContractId',
            newValue: true,
          })

          return

        }

        this.updateContractOptions()

      },
      updateContractOptions() {

        const selectedNetworkSlug = this.selectedNetworkOption.apiRecord.slug

        let newOptions = null
        let fieldName = 'contractId'

        switch (this.selectedTriggerOption.apiRecord.type) {

          case 'balance-change':
            newOptions = []
              .concat(this.$store.state.app.networkContractOptionsByTypeSlugMap[selectedNetworkSlug].base || [])
              .concat(this.$store.state.app.networkContractOptionsByTypeSlugMap[selectedNetworkSlug]['erc-20'] || [])
            break

          case 'nft':
            newOptions = []
              .concat(this.$store.state.app.networkContractOptionsByTypeSlugMap[selectedNetworkSlug]['erc-721'] || [])
              .concat(this.$store.state.app.networkContractOptionsByTypeSlugMap[selectedNetworkSlug]['erc-1155'] || [])
            break

          case 'user-contract': {
            fieldName = 'userContractId'
            newOptions = []
              .concat(this.$store.state.user.networkUserContractOptionsSlugMap[selectedNetworkSlug] || [])
              .concat(this.$store.state.app.networkContractOptionsByTypeSlugMap[selectedNetworkSlug]['erc-20'] || [])
              .concat(this.$store.state.app.networkContractOptionsByTypeSlugMap[selectedNetworkSlug]['erc-721'] || [])
              .concat(this.$store.state.app.networkContractOptionsByTypeSlugMap[selectedNetworkSlug]['erc-1155'] || [])
              .concat(this.$store.state.app.networkContractOptionsByTypeSlugMap[selectedNetworkSlug].other || [])
            break
          }

          default:
            return

        }

        this.$store.commit('forms/SET_FIELD_OPTIONS', {
          formName: this.formName,
          newOptions,
          fieldName,
        })

        this.$store.commit('forms/SET_FIELD_DISABLED', {
          formName: this.formName,
          newValue: false,
          fieldName,
        })

      },
      resetAllUserContractFields(includeUserContractId = true) {

        if (includeUserContractId) {
          this.$store.commit('forms/RESET_FORM_FIELD', {
            fieldName: 'userContractId',
            formName: this.formName,
          })
          this.$store.commit('forms/SET_FIELD_DISABLED', {
            newValue: !this.selectedNetworkOption,
            fieldName: 'userContractId',
            formName: this.formName,
          })
        }

        this.$store.commit('forms/RESET_FORM_FIELD', {
          fieldName: 'userContractType',
          formName: this.formName,
        })

        this.$store.commit('forms/RESET_FORM_FIELD', {
          fieldName: 'userContractAddress',
          formName: this.formName,
        })

        this.$store.commit('forms/RESET_FORM_FIELD', {
          fieldName: 'userContractProxyEvents',
          formName: this.formName,
        })

        this.$store.commit('forms/RESET_FORM_FIELD', {
          fieldName: 'userContractImplementationEvents',
          formName: this.formName,
        })

        this.$store.commit('forms/RESET_FORM_FIELD', {
          fieldName: 'userContractProxyFunctions',
          formName: this.formName,
        })

        this.$store.commit('forms/RESET_FORM_FIELD', {
          fieldName: 'userContractImplementationFunctions',
          formName: this.formName,
        })

        this.$store.commit('forms/SET_FIELD_OPTIONS', {
          formName: this.formName,
          fieldName: 'userContractProxyEvents',
          newOptions: [],
        })

        this.$store.commit('forms/SET_FIELD_OPTIONS', {
          formName: this.formName,
          fieldName: 'userContractImplementationEvents',
          newOptions: [],
        })

        this.$store.commit('forms/SET_FIELD_OPTIONS', {
          formName: this.formName,
          fieldName: 'userContractProxyFunctions',
          newOptions: [],
        })

        this.$store.commit('forms/SET_FIELD_OPTIONS', {
          formName: this.formName,
          fieldName: 'userContractImplementationFunctions',
          newOptions: [],
        })

      },
      getContractABIEntryOptions(contractId, abiEntryType) {

        if (abiEntryType !== 'events' && abiEntryType !== 'functions') {
          return Promise.reject(new Error(`Invalid abiEntryType "${abiEntryType}" passed to getContractABIEntryOptions()`))
        }

        return this.$store.state.api.dispatch.get(`/contracts/${contractId}/${abiEntryType}`)
          .then((response) => {

            const proxyABIEntryOptions = []
            const implementationABIEntryOptions = []

            response.data.forEach((abiEntry) => {

              const { abiEntrySignature, abiEntryName, abiEntryArguments } = this.getABIEntrySignaturePieces(abiEntry.signature)

              const abiEntryOption = {
                iconUrl: null,
                label: abiEntryName,
                apiRecord: abiEntry,
                id: abiEntrySignature,
                value: abiEntrySignature,
                description: abiEntryArguments,
              }

              if (abiEntry.type === 'proxy') {
                proxyABIEntryOptions.push(abiEntryOption)
              } else {
                implementationABIEntryOptions.push(abiEntryOption)
              }

            })

            return [proxyABIEntryOptions, implementationABIEntryOptions]

          })
          .catch((error) => {

            this.$store.dispatch('toast/CREATE_TOAST', {
              text: `Could not retrieve smart contract ${abiEntryType}! Please try again later.`,
              type: 'error',
            })

            throw error

          })

      },
      openTokenRequestModal() {

        const networkName = this.selectedNetworkOption && this.formFields.networkId.options.length > 1
          ? this.selectedNetworkOption.label
          : ''

        this.$store.dispatch('modals/OPEN_MODAL', {
          name: 'TokenRequestModal',
          props: {
            networkName,
            type: this.isNFTTrigger ? 'nft' : 'token',
          },
        })

      },
      openCreateUserContractModal() {

        this.resetAllUserContractFields()

        this.$store.dispatch('modals/OPEN_MODAL', {
          name: 'CreateUserContractModal',
          props: {
            networkId: this.selectedNetworkOption ? this.selectedNetworkOption.apiRecord.id : null,
            onSuccess: (newUserContract) => {

              this.updateContractOptions()

              this.$store.commit('forms/SET_FIELD_VALUE', {
                newValue: newUserContract.networkId,
                fieldName: 'networkId',
                formName: this.formName,
              })

              // delay this by a tick so the networkId watcher has time to do
              //  it's stuff (including clearing out the userContractId value)
              //  before we update userContractId
              this.$nextTick(() => {
                this.$store.commit('forms/SET_FIELD_VALUE', {
                  newValue: newUserContract.id,
                  fieldName: 'userContractId',
                  formName: this.formName,
                })
              })

            },
          },
        })

      },
    },
  }

</script>

<style lang="stylus" scoped>

  @import '~@/assets/styles/details-list.styl'

  main
    @apply bg-white

    min-height: calc(100% - 128px)

  .container
    @apply p-4
    @apply pt-8
    @apply mx-auto

  .return-to-dashboard-link
    @apply w-max
    @apply text-gray-800

    @apply flex
    @apply items-center

    &:hover:not([disabled])
      @apply text-gray-700

    svg
      @apply w-4
      @apply h-4
      @apply mr-2
      @apply transform
      @apply rotate-180

  form
    @apply mb-12
    @apply w-full
    @apply mx-auto

    max-width: 48rem

  h2
    @apply pt-8
    @apply pb-4
    @apply text-center

  .step
    @apply p-4
    @apply shadow-md
    @apply rounded-sm
    @apply bg-purple-100

    &+.step
      @apply mt-4

    +breakpoint(sm)
      @apply py-6
      @apply px-12

  .step-header
    @apply flex
    @apply items-center

    h5
      @apply mb-0

    .check-icon
      @apply w-8
      @apply h-8
      @apply text-success-500

    .step-number
      @apply w-6
      @apply h-6
      @apply p-2
      @apply mr-2
      @apply block
      @apply text-xs
      @apply bg-gray-400
      @apply rounded-full
      @apply text-gray-1000

      line-height: .5rem

    .edit-step-link
      @apply ml-2
      @apply self-end
      @apply font-normal

      +breakpoint(sm)
        @apply ml-4

  .step-body
    @apply mt-4
    @apply px-8

    +breakpoint(sm)
      @apply px-16

  .step-buttons
    @apply space-y-4

    @apply flex
    @apply flex-col

    +breakpoint(sm)
      @apply space-y-0
      @apply space-x-4

      @apply flex-row
      @apply justify-center

  .step-summary
    @apply mt-4
    @apply px-8

  .multi-address-input-container
    @apply flex

    .number
      @apply mt-3
      @apply mr-2
      @apply ml-4

  .test-action-result-header
    @apply my-4
    @apply pt-4
    @apply flex
    @apply items-center

    @apply border-t
    @apply border-gray-500

    h4
      @apply mb-0

    svg
      @apply w-8
      @apply h-8
      @apply ml-3

  .test-action-result
    @apply p-4
    @apply mt-4
    @apply bg-white
    @apply break-words

    +breakpoint(sm)
      @apply p-8

  .trigger-conditions
    .step-body:empty
      @apply mb-4

      &::before
        content: 'This Trigger doesnt need any other conditions! Press "Continue" to proceed.'

    .step-summary:empty
      &::before
        content: 'This Trigger type has no custom conditions.'

  .token-request-note
    @apply p-3
    @apply border-t
    @apply text-center
    @apply border-gray-400

</style>
