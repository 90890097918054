<template>
  <div class="input-container">

    <div class="label-container">
      <FormLabel
        v-if="showLabel"
        :formName="formName"
        :fieldName="fieldName"
      />
      <slot name="label-extra-content" />
    </div>

    <FormLabelDescription
      :formName="formName"
      :fieldName="fieldName"
      v-if="field.labelDescription"
    />

    <div class="rich-checklist" :class="{ 'contract-abi-entry': type === 'contractABIEntry', 'is-loading': isLoading }">
      <LoadingOverlay type="light" v-if="isLoading" />
      <div
        :key="option.id"
        class="option-container"
        @mouseup="onOptionClick(option)"
        v-for="(option, index) in field.options"
      >
        <input
          @mouseup.stop
          ref="formElement"
          :type="field.type"
          v-model="field.value"
          :value="option.value"
          :id="`${uuid}-${index}`"
          @change="$emit('change', field)"
          :disabled="field.disabled || option.disabled"
          :class="{ 'error': field.isDirty && !!field.error, 'toggle': isToggle }"
        />
        <div class="option-content" :class="{ 'disabled': field.disabled || option.disabled }">
          <ContractABIEntrySignature :abiEntrySignature="option.value" />
        </div>
      </div>
    </div>

    <FormNote
      v-if="field.note"
      :formName="formName"
      :fieldName="fieldName"
    />

    <FormError
      v-if="showError"
      :formName="formName"
      :fieldName="fieldName"
    />

  </div>
</template>

<script>

  import { toRefs } from 'vue'

  import useFormField from '@/composables/useFormField'

  import FormNote from '@/components/forms/FormNote.vue'
  import FormError from '@/components/forms/FormError.vue'
  import FormLabel from '@/components/forms/FormLabel.vue'
  import FormLabelDescription from '@/components/forms/FormLabelDescription.vue'

  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'
  import ContractABIEntrySignature from '@/components/etc/ContractABIEntrySignature.vue'

  export default {
    emits: ['change'],
    components: {
      FormNote,
      FormError,
      FormLabel,
      LoadingOverlay,
      FormLabelDescription,
      ContractABIEntrySignature,
    },
    props: {
      formName: {
        type: String,
        required: true,
      },
      fieldName: {
        type: String,
        required: true,
      },
      isToggle: {
        type: Boolean,
        default: false,
      },
      showLabel: {
        type: Boolean,
        default: true,
      },
      showError: {
        type: Boolean,
        default: true,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'normal',
        validator: (value) => {
          return value === 'normal' || value === 'contractABIEntries'
        },
      },
    },
    setup(props) {
      return useFormField(toRefs(props))
    },
    methods: {
      onOptionClick(option) {

        if (this.field.disabled || option.disabled) return

        const newValue = this.field.value.filter((value) => {
          return value !== option.value
        })

        if (!this.field.value.includes(option.value)) {
          newValue.push(option.value)
        }

        this.$store.commit('forms/SET_FIELD_VALUE', {
          fieldName: this.fieldName,
          formName: this.formName,
          newValue,
        })

      },
    },
  }

</script>

<style lang="stylus" scoped>

  input
    @apply mt-1

  .rich-checklist
    @apply border
    @apply w-full
    @apply relative
    @apply bg-white
    @apply max-h-64
    @apply rounded-sm
    @apply border-gray-500
    @apply overflow-y-scroll

    &.is-loading
      min-height: theme('height.32')

    &.contract-abi-entry
      .option-container
        @apply font-mono

      .argument-type
        @apply text-purple-500

  .option-container
    @apply px-3
    @apply py-1
    @apply w-full
    @apply text-base

    @apply items-start
    @apply text-gray-800
    @apply cursor-pointer

    >svg
    >img
    >div
      @apply pointer-events-none

    &:first-child
      @apply pt-3

    &:last-child
      @apply pb-3

    &:hover
    &.focused
      @apply bg-purple-200
      @apply text-gray-1000

  .option-content
    @apply ml-2

    &.disabled
    &.disabled .label-text
      @apply opacity-50
      @apply cursor-not-allowed

</style>
